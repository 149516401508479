@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

$hamburger-size: 3rem;
$hamburger-height: 2.5rem;
$hamburger-width: $hamburger-height * 0.8;

.hamburger_container {
  position: relative;
  display: flex;
  width: $hamburger-width;
  height: $hamburger-height;
  padding: ($hamburger-size - $hamburger-height) * 0.15 0;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: space-around;
  border: none;
  outline: none;
  background-color: transparent;
  @include transition(background-color);

  &:hover {
    cursor: pointer;
  }

  &:focus-within,
  &:hover,
  &.active {
    .hamburger_strip {
      background-color: $warning;
    }
  }

  &.active {
    .hamburger_strip {
      &:first-of-type {
        width: 50%;
      }
      &:nth-of-type(2) {
        width: 75%;
      }
      border-radius: 0 10px 10px 0;
    }
  }
}

.hamburger_strip {
  height: $hamburger-height * 0.1;
  width: $hamburger-width;
  background-color: rgba($text-color, 0.2);
  @include transition(all);
}
