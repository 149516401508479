@import 'styles/theme-variables.scss';
@import 'styles/mixins.scss';

$circle-border-size: 0.125em;
$circle-size: 2.25em - ($circle-border-size * 2);
$circle-offset: 0.75em;

$line-width: 0.225em;
$line-height: $circle-size * 0.75;
$active-line-color: rgba($selected-text, 0.7);

$icon-size: $circle-size * 0.7;
$disabled-color: desaturate($accent, 30);

.item_container {
  &:not(:last-of-type) {
    .vertical_line {
      position: relative;
      margin: 0.75em 0;
      margin-right: 1em;
      min-height: 2em;
      width: $line-width;
      background-color: rgba($disabled-color, 0.75);
      border-radius: 0.5rem;
      font-size: 1em;
      flex: 1;
      @include transition(background-color);

      &.disabled {
        background-color: $disabled-color;
      }
    }
  }

  .item_circle {
    position: relative;
    width: $circle-size;
    height: $circle-size;
    background-color: $base-text;
    border: $circle-border-size solid $base-text;
    border-radius: 50%;
  }

  .item_icon {
    color: $accent;
    width: $icon-size;
    height: $icon-size;
    @include centerAbsolutely();
    @include transition(color);

    &_letter {
      font-size: $icon-size;
      width: auto;
      height: auto;
      line-height: 1;
      user-select: none;
    }
  }

  div.icon_column {
    flex: 4;
    align-items: flex-end;
  }

  &:not(.completed) {
    div.content {
      &_left,
      &_right {
        margin-top: 0.6em;
      }
    }

    .item_circle {
      background-color: $disabled-color;
      border-color: $disabled-color;
    }
  }

  &.completed {
    .item_circle {
      box-shadow: 2px 5px 6px -1px rgba(0, 0, 0, 0.35);
    }

    .item_icon {
      color: $selected-text;
    }

    .vertical_line {
      background-color: $active-line-color;
    }
  }
}
