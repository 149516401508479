@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$table-breakpoint: 700px;

.bids_table {
  display: flex;
  min-width: 300px;
  min-height: 14.5em;
  border-collapse: collapse;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  thead,
  tbody {
    display: block;
    z-index: 1;
  }

  tbody {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    @include scrollableComponent(
      $accent,
      darken($accent, 7),
      lighten($accent, 7),
      true
    );
  }

  thead {
    tr {
      display: block;
      // TODO Vorbert -> zrobić mixina do border-ów;
      border-bottom: 1px solid rgba($selected-text, 0.7);
      width: 100%;

      th {
        &.header {
          &_index {
            @include mid-fluid-value(width, 2em, 2.5em);
          }

          &_offerts {
            @include mid-fluid-value(width, 7em, 8.25em);
          }

          &_time {
            @include mid-fluid-value(width, 6.15em, 7.25em);
          }
        }
      }
    }
  }

  tbody {
    tr {
      position: relative;
      display: block;
      padding: 0.75em 0;

      &:nth-of-type(even) {
        background-color: darken($accent, 2);
      }

      td {
        &,
        span:not([class]) {
          @include mid-fluid-value(font-size, 0.85rem, 1rem);
        }

        &.cell {
          &_index {
            width: 1.75em;
            text-align: center;
            padding-right: 0.75em;
          }

          &_offert {
            @include mid-fluid-value(width, 5em, 5.5em);
          }

          &_time {
            @include mid-fluid-value(width, 5.5em, 6em);
          }

          &_offert,
          &_company_name,
          &_time {
            padding-left: 0.25em;
          }

          &_company_name {
            line-height: 130%;

            span {
              display: inline-block;
              margin-top: -0.15em;
            }
          }

          &_time {
            .hours_label {
              // TODO Vorbert -> zrobić mixins-a dla wartości rosnacych, wraz ze zmniejszeniem szerokości ekranu
              padding-top: 0.225em;
            }
          }

          &_label {
            width: 4rem;
            text-align: right;
          }
        }
      }
    }
  }

  th {
    display: none;
    color: rgba($selected-text, 0.9);
    font-weight: 500;
    @include quickTransition();
    @include mid-fluid-value(font-size, 0.75rem, 0.8rem);

    &.header {
      &_index {
        text-align: center;
      }

      &_offerts,
      &_suppliers {
        padding-left: 1em;
      }
    }
  }

  td {
    display: block;

    &:before {
      content: attr(data-table-header) ': ';
      display: inline-block;
      width: 8em;
      font-weight: 700;

      @media (min-width: $table-breakpoint) {
        display: none;
      }
    }

    span {
      font-weight: 500;

      &.index_cell {
        margin-left: 0.35em;
      }
    }
  }

  th,
  td {
    display: table-cell;
    text-align: left;
    vertical-align: top;
  }

  th {
    padding: 0.75em 0.25em;
  }

  td {
    padding: 0 0.25em;
  }

  th {
    box-sizing: border-box;
  }

  td {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .badge {
    &_row {
      margin-top: 0.35em;
    }
  }
}

.bids_table {
  thead {
    th {
      &:nth-of-type(2) {
        @include mid-fluid-value(min-width, 3.75rem, 5rem);
      }

      @include mid-fluid-value(min-width, 0.9rem, 1.15rem);
    }
  }
}

.difference_label {
  position: relative;
  width: fit-content;
  margin-top: 0.2em;
  padding: 0.4em 0.785em;
  background-color: $series-e;
  border-radius: 2rem;
  color: rgba($selected-text, 0.7);
  line-height: 100%;
  @include mid-fluid-value(font-size, 0.6rem, 0.7rem);

  &_accent {
    color: $info;
    font-weight: 500;
  }
}
