@import './theme-variables.scss';
@import './variables.scss';

@keyframes spinning {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes longShake {
  1%,
  9% {
    transform: translate3d(-1px, 0, 0);
  }

  2%,
  8% {
    transform: translate3d(2px, 0, 0);
  }

  3%,
  5%,
  7% {
    transform: translate3d(-3px, 0, 0);
  }

  4%,
  6% {
    transform: translate3d(3px, 0, 0);
  }
}

@keyframes skewedShake {
  0% {
    transform: skewX(-8deg) rotate(7.5deg);
  }
  100% {
    transform: skewX(8deg) rotate(-7.5deg);
  }
}

@keyframes shiningText {
  25%,
  100% {
    background-position: -200% center;
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes badgeBackgroundAppearing {
  0% {
    width: 0;
    height: 0;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}

@keyframes badgeAppearing {
  0% {
    background-color: transparent;
    color: transparent;
  }

  100% {
    background-color: var(--badge-background-color);
    color: var(--badge-text-color);
  }
}

@keyframes clickableBadgeAppearing {
  0% {
    background-color: transparent;
    color: transparent;
    box-shadow: 0 0 0 0 transparent;
  }

  100% {
    background-color: var(--badge-background-color);
    color: var(--badge-text-color);
    box-shadow: 0 0.25rem 1rem -0.25rem rgba(23, 23, 23, 0.4);
  }
}

@keyframes borderBadgeAppearing {
  0% {
    background-color: transparent;
    color: transparent;
    border-color: transparent;
  }

  100% {
    background-color: $selected-text;
    color: var(--badge-background-color);
    border-color: var(--badge-background-color);
  }
}

@keyframes invertedBadgeAppearing {
  0% {
    background-color: transparent;
    color: transparent;
  }

  100% {
    background-color: $selected-text;
    color: var(--badge-background-color);
  }
}

@keyframes badgeHiding {
  0% {
    background-color: var(--badge-background-color);
    color: var(--badge-text-color);
  }

  100% {
    color: transparent;
    background-color: transparent;
  }
}

@keyframes shiningText {
  25%,
  100% {
    background-position: -200% center;
  }
}
