// Usuwanko k-selecta z telerikowych inputów. K-select stanowi np. kontener dla przycisków do zwiększania/zmniejszania wartości numeric inputa
.remove {
  $classNames: ('k-select');

  @each $className in $classNames {
    &-#{$className} {
      .#{$className} {
        display: none !important;
      }
    }
  }
}
