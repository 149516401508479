@import '../styles/theme-variables.scss';

$row-offset: 1.25rem;
$divider-height: 0.0625rem;

.main_column {
  & > div {
    &:first-of-type {
      margin-bottom: $row-offset;
    }
    &:last-of-type {
      position: relative;
      margin-top: $row-offset;

      //  TODO Vorbert -> zrobic mixinsa do dividerów
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: $row-offset * -0.85 + $divider-height * 0.5;
        width: 100%;
        height: $divider-height;
        background-color: $hovered-bg;
      }
    }
  }
}
