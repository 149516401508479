@import '../../../styles/theme-variables.scss';
@import '../../../styles/mixins.scss';

.form_preview_section_wrapper {
  margin: 0 auto;
  width: fit-content;
  max-width: 100%;
  box-sizing: border-box;

  @include transition();

  &.wider {
    max-width: calc(100vw - 60px);
  }
}
