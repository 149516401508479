@import '../../styles/variables.scss';
@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

.header {
  background-color: $accent;
  color: white;
  display: flex;
  // TODO Vorbert -> wyekstraktować box-shadow do mixinsów/placeholderów
  box-shadow: 0 12px 8px -2px rgba(0, 0, 0, 0.25);

  @include mid-fluid-value(padding-top, 1.5rem, 2rem);
  @include mid-fluid-value(padding-right, 3rem, 4rem);
  @include mid-fluid-value(padding-bottom, 1.5rem, 2rem);
  @include mid-fluid-value(padding-left, 1rem, 1.5rem);

  span:not([class]) {
    display: block;
  }
}

.label_header {
  line-height: 100%;
  font-weight: 600;
  @include quickTransition(font-size);
  @include mid-fluid-value(font-size, 1.15rem, 1.5rem);
}

.label_small {
  margin-top: 0.15rem;
  @include mid-fluid-value(font-size, 0.8rem, 0.9rem);
}

.label_time_left {
  @include quickTransition();
  @include mid-fluid-value(font-size, 1.7rem, 2.25rem);
  font-weight: 600;
}

.company_data {
  margin: 0.1rem;
  margin-left: 0;
  font-size: 1.2rem;
  max-width: 350px;

  &_name {
    font-weight: 600;
    @include mid-fluid-value(font-size, 1rem, 1.2rem);
  }

  &_location {
    @include mid-fluid-value(font-size, 0.9rem, 1.2rem);
  }
}

.left_offset {
  @include mid-fluid-value(margin-left, 3rem, 5.5rem);
}
