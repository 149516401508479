@import '../../../styles/theme-variables.scss';
@import '../../../styles/mixins.scss';

.preview_item {
  // Gdy item nie jest pierwszym cusime wyświetlonym po tytule (tytuł to też div, dlatego nth-of-TYPE, nie przejdzie tak 'gładko' xD)
  &:not(:nth-of-type(2)),
  &.have_margin {
    @include laptop-fluid-value(margin-top, 0.6rem, 0.9rem);
  }
}

.label {
  color: rgba($selected-text, 0.7);
  @include laptop-fluid-value(font-size, 0.85rem, 1rem);
}

.value {
  margin-left: 0.25rem;
  line-height: 1.2rem;
  font-weight: 500;
  @include laptop-fluid-value(font-size, 0.85rem, 1rem);
}

.label,
.value {
  display: inline;
}

.badge {
  display: inline;
  padding: 0.1rem 0.65rem;
  margin-right: 0.25rem;
  margin-left: 0.15rem;
  background-color: $info;
  border-radius: 1rem;
  color: $selected-text;
  font-weight: 500;
  @include laptop-fluid-value(font-size, 0.765rem, 0.9rem);
}
