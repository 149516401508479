@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.button {
  $buttonSelector: &;

  display: inline-block;
  background-color: mix($accent, $selected-text, 40);
  color: $accent;
  font-size: 1.275rem;
  font-weight: 800;
  padding: 0.3rem 0.5em;
  border-radius: 0.5em;
  @include quickTransition();

  &:hover,
  &:focus {
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    #{$buttonSelector}_icon_withAnimation {
      transform: rotate(180deg);
    }

    cursor: pointer;
  }

  &:active {
    transform: scale(0.95);
  }

  &_icon {
    font-size: 0.8em;
    margin-right: 0.5em;
    @include transition();
  }
}
