@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

$pane-border-radius: 0.75rem;

.pane {
  &,
  * {
    box-sizing: border-box;
  }

  &_left {
    flex: 1;
    background-color: $accent;
    border-radius: $pane-border-radius 0 0 $pane-border-radius;
    @include mid-fluid-value(margin-top, 3.25rem, 4.08rem);
    @include mid-fluid-value(margin-bottom, 3.25rem, 4.08rem);

    &_top {
      @include mid-fluid-value(height, 13.52rem, 15.27rem);
      box-shadow: 0 12px 8px -2px rgba(0, 0, 0, 0.25);
    }

    &_mid {
      flex: 1;
      background-color: $selected-text;
      border-left: 2px solid rgba($text-color, 0.15);
    }

    &_bottom {
      box-shadow: 0 -8px 8px -2px rgba(0, 0, 0, 0.25);
      @include mid-fluid-value(height, 6.6rem, 7.12rem);
    }

    &_top,
    &_bottom {
      z-index: 1;
    }
  }

  &_right {
    background-color: $accent;
    border-radius: $pane-border-radius 0 0 $pane-border-radius;
    height: 100vh;
  }
}
