@import '../styles/theme-variables.scss';

.login_form_container {
  width: 100%;

  .k-form {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;

    .k-button {
      width: 100%;
      font-weight: 500;
      padding: 1.1rem 0;
    }

    .k-form-field,
    input {
      margin-left: 0 !important;
      width: 100%;
    }

    .k-form-field:last-of-type {
      text-align: right;
      margin-bottom: 0.6rem;

      a {
        color: $accent;
        text-decoration: none;
        font-weight: 500;
      }

      span {
        margin-right: 0.25rem;
        margin-bottom: 0.25rem;
      }
    }
  }
}
