/*!
 * Font Awesome Pro 5.12.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

@for $size from 1 to 10 {
  .fa-#{$size}x {
    font-size: #{$size}em;
  }
}
