@import '../../styles/theme-variables.scss';

$offsetDirections: ('top', 'right', 'bottom', 'left');

.spinner {
  $border-width: 0.125em;
  $spinner-size: 1em - ($border-width * 2);
  $spinner-offset: 0.75em;

  width: $spinner-size;
  height: $spinner-size;
  background-color: transparent;
  border-radius: 50%;
  border: $border-width solid $selected-text;
  border-color: $selected-text transparent $selected-text transparent;
  animation: rotatingCircle 1.2s linear infinite;

  &.offset {
    @each $direction in $offsetDirections {
      &_#{$direction} {
        margin-#{$direction}: $spinner-offset;
      }
    }
  }
}

@keyframes rotatingCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
