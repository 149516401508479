@import '../styles/theme-variables.scss';

.main_container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.left_side {
  height: 100%;
  flex: 1;
  min-height: 100vh;
  background-color: $base-bg;
}

.right_side {
  position: relative;
  flex: 1.5;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;

  img {
    display: block;
    height: 100%;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($accent, 0.2);
  }
}

.login_wrapper {
  height: 84vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
