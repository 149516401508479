$radius-size: 0.75rem;

.page_wrapper {
  & > * {
    box-sizing: border-box;

    &:first-child {
      border-top-left-radius: $radius-size;
      border-top-right-radius: $radius-size;
    }

    &:last-child {
      border-bottom-left-radius: $radius-size;
      border-bottom-right-radius: $radius-size;
    }
  }

  &.rounded {
    border-radius: $radius-size;

    &_left {
      & > * {
        &:first-child {
          border-top-right-radius: 0;
        }

        &:last-child {
          border-bottom-right-radius: 0;
        }
      }
    }

    &_right {
      & > * {
        &:first-child {
          border-top-left-radius: 0;
        }

        &:last-child {
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}
