@import '../../styles/theme-variables.scss';

.outstanding_data {
  font-weight: 600;
  font-size: 1.15em;
  color: rgba($text-color, 0.8);
}

.cell_align {
  &_top {
    vertical-align: top !important;
  }

  &_center {
    vertical-align: middle !important;
  }

  &_bottom {
    vertical-align: bottom !important;
  }
}
