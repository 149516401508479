@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

$default-icon-size: 2.5rem;
$small-icon-size: 2rem;
$yellow: $series-b;

.toggle_container {
  &:hover {
    cursor: pointer;
  }

  input[type='checkbox'] {
    display: none;

    &:checked ~ .star_container {
      .filled {
        opacity: 1;
      }
    }

    &:disabled ~ .star_container {
      .filled {
        opacity: 0;
      }

      .unfilled,
      .filled {
        color: desaturate($yellow, 100);
      }
    }

    & ~ .star_container {
      &:active {
        transform: scale(0.8);
      }
    }

    &:not(:disabled) ~ .star_container {
      &:active {
        .filled {
          opacity: 1;
        }
      }
    }

    &:checked ~ .star_container {
      &:active {
        .filled {
          opacity: 0;
        }
      }
    }
  }
}

.star_container {
  position: relative;
  width: fit-content;
  @include transition();

  &:hover {
    transform: rotate(144deg);
  }

  .star_icon {
    color: $yellow;
    @include mid-fluid-value(
      width,
      $default-icon-size * 0.75,
      $default-icon-size
    );
    @include mid-fluid-value(
      height,
      $default-icon-size * 0.75,
      $default-icon-size
    );

    &.filled {
      position: absolute;
      left: 0;
      opacity: 0;
    }
  }

  &.small_icon {
    .star_icon {
      width: $small-icon-size;
      height: $small-icon-size;
    }
  }
}
