@import './theme-variables.scss';

$default-transition-time: 350ms;
$quick-transition-time: 150ms;

$grey-text: lighten($text-color, 40);
$grey-notification-bell: lighten($text-color, 20);

$preview-layout-column-spacing: 2rem;

$small-avatar-size: 30px;

$max-side-bar-width: 13.75rem;

$content-wrapper-vertical-offset: 1.5rem;

$form-single-card-size: 43%;

$default-offset: 0.75rem;

$section-icon-size: 3rem;

$form-section-font-size: 1.25rem;
$form-section-icon-font-size: $form-section-font-size * 1.75;
$form-section-icon-offset: 1rem;

$default-card-width: 760px;

$menu-item-offset: 1.25rem;

$custom-green: darken(adjust-hue($success, 50deg), 15);

$scrollbar-width: 0.5rem;

// Breakpoint-y do media queries
$desktop-width: 1800px;
$big-laptop-screen: 1680px;
$medium-laptop-screen: 1440px;
$small-laptop-screen: 1360px;

$column-break-width: 1550px;

$grey: darken($grey-text, 10);
