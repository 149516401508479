@import '../../styles/theme-variables.scss';

$delay: 500ms;
$duration: 1200ms;
$inner-vertical-row-offset: 0.675rem;
$inner-horizontal-row-offset: 0;

.revealer {
  position: absolute;
  top: 0.5em;
  left: $inner-horizontal-row-offset;
  right: $inner-horizontal-row-offset;
  height: calc(100% - 1em);
  bottom: 0.5em;
  background-color: mix($accent, $success, 20);
  z-index: 999;
  transform: scaleX(0);
  transform: 0 50%;
  pointer-events: none;
  animation: revealerAppearing $duration $delay ease-in-out;
  animation-fill-mode: both;
  border-radius: 0.25rem;

  &_container {
    &:nth-of-type(even) {
      animation: revealContainerBackground 350ms $delay + $duration * 0.5
        backwards;
    }
    & > td {
      animation: revealContent 350ms $delay + $duration * 0.5 backwards;
    }
  }
}

@keyframes revealerAppearing {
  0%,
  50% {
    transform-origin: 0 50%;
  }

  60%,
  100% {
    transform-origin: 100% 50%;
  }

  55% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

@keyframes revealContent {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes revealContainerBackground {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: darken($accent, 2);
  }
}
