@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$vertical-offset: 0.75rem;
$horizontal-offset: 3rem;
$icon-size: 2rem;
$icon-offset: 0.75rem;

.toast {
  position: relative;
  padding: $vertical-offset $horizontal-offset;
  padding-left: $icon-offset;
  padding-right: 3.45rem;
  margin-right: 0;
  margin-left: auto;
  height: auto;
  width: fit-content;
  box-shadow: 0px 4px 17px 3px rgba(89, 89, 89, 0.35);
  box-sizing: border-box;
  border-radius: 0.45rem;
  font-family: 'Roboto', sans-serif;
  color: #f4f4f4;
  overflow: hidden;
  /* Style na potrzeby animacji */
  right: -115%;
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 800ms ease-out, right 400ms ease-in-out,
    transform 500ms 200ms ease-out, height 350ms 150ms ease-in-out,
    padding 350ms 150ms ease-in-out;

  &.visible {
    right: 0;
    opacity: 1;
    transform: scale(1);
  }
}

.icon_circle {
  position: relative;
  margin-right: $icon-offset;
  width: $icon-size;
  height: $icon-size;
  background-color: #f4f4f4;
  border-radius: 50%;

  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
  }
}

.text_container {
  padding: 0.5rem 0 0.25rem;
  word-break: break-word;
}

.row,
.column {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
  align-self: center;
}

.background {
  &_success {
    background-color: hsl(120, 65%, 55%);
  }

  &_info {
    background-color: #1d72f3;
  }

  &_warning {
    background-color: hsl(35, 100%, 50%);
  }

  &_error {
    background-color: #e53935;
  }

  &_dark {
    background-color: #2a2927;
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem !important;
  height: 1rem;
  transform: translate(-50%, -50%);

  &_success {
    color: hsl(120, 65%, 55%);
  }

  &_info {
    color: #1d72f3;
  }

  &_warning {
    color: hsl(35, 100%, 50%);
  }

  &_error {
    color: #e53935;
  }

  &_dark {
    color: #2a2927;
  }
}
