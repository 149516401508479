@import './theme-variables.scss';
@import './variables.scss';

@mixin flex(
  $direction: column,
  $justifyContent: flex-start,
  $alignItems: stretch
) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin box-shadow($xOffset: 0, $yOffset: 2px, $blur: 15px, $spread: -5px) {
  box-shadow: $xOffset $yOffset $blur $spread $hovered-bg;
}

@mixin transition($propertyName: all, $timeFunction: ease) {
  transition: $propertyName $default-transition-time $timeFunction;
}

@mixin quickTransition($propertyName: all, $timeFunction: ease) {
  transition: $propertyName $quick-transition-time $timeFunction;
}

@mixin centerAbsolutely($centerDirection: center) {
  position: absolute;

  @if $centerDirection == vertical {
    top: 50%;
    transform: translateY(-50%);
  } @else if $centerDirection == horizontal {
    left: 50%;
    transform: translateX(-50%);
  } @else {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// TODO vorbert -> dopracować dla wartości === 0
@mixin fluidValue(
  $propertyName,
  $minValue,
  $valueDiff,
  $minFluidWidth,
  $widthDiff,
  $important: !important
) {
  #{$propertyName}: calc(
      #{$minValue} + #{$valueDiff} *
        ((100vw - #{$minFluidWidth}) / #{$widthDiff})
    )
    #{$important};
}

// Czcionka === 1rem na urządzeniach <= 1680px && >= 1440px
// TODO vorbert -> ogarnąć czemu jest tutaj potrzebny important ;o (czemu nie jest to ważniejsze od innych zasad ;o)
%normalLaptopFontSize {
  @media screen and (min-width: $medium-laptop-screen) and (max-width: $big-laptop-screen) {
    font-size: calc(
      0.8rem + (16 * 0.2) * ((100vw - #{$medium-laptop-screen}) / 240)
    ) !important;
  }

  @media screen and (max-width: #{$medium-laptop-screen - 1}) {
    font-size: 0.8rem !important;
  }
}

@function getRidOfUnitType($value) {
  @return $value / ($value * 0 + 1);
}

// TODO Vorbert -> wyekstraktować mechanizm mixinsa do osobnego mixinsa oraz dodać możliwosc dodawania flagi !important dla uwielbianego telerika xD

@mixin mid-fluid-value(
  $propertyName,
  $min-value,
  $max-value,
  $min-width: $medium-laptop-screen,
  $max-width: $big-laptop-screen
) {
  & {
    #{$propertyName}: $max-value;

    @media screen and (min-width: $min-width) and (max-width: $max-width) {
      #{$propertyName}: calc(
        #{$min-value} + (16 * #{getRidOfUnitType($max-value - $min-value)}) * ((
                100vw - #{$min-width}
              ) / #{getRidOfUnitType($max-width - $min-width)})
      );
    }

    @media screen and (max-width: #{$min-width - 1}) {
      #{$propertyName}: $min-value;
    }
  }
}

@mixin important-mid-fluid-value(
  $propertyName,
  $min-value,
  $max-value,
  $min-width: $medium-laptop-screen,
  $max-width: $big-laptop-screen
) {
  & {
    #{$propertyName}: $max-value !important;

    @media screen and (min-width: $min-width) and (max-width: $max-width) {
      #{$propertyName}: calc(
        #{$min-value} + (16 * #{getRidOfUnitType($max-value - $min-value)}) * ((
                100vw - #{$min-width}
              ) / #{getRidOfUnitType($max-width - $min-width)})
      ) !important;
    }

    @media screen and (max-width: #{$min-width - 1}) {
      #{$propertyName}: $min-value !important;
    }
  }
}

@mixin laptop-fluid-value($propertyName, $min-value, $max-value) {
  @include mid-fluid-value(
    $propertyName,
    $min-value,
    $max-value,
    $medium-laptop-screen,
    $desktop-width
  );
}

@mixin important-laptop-fluid-value($propertyName, $min-value, $max-value) {
  @include important-mid-fluid-value(
    $propertyName,
    $min-value,
    $max-value,
    $medium-laptop-screen,
    $desktop-width
  );
}

@mixin small-laptop-fluid-value($propertyName, $min-value, $max-value) {
  @include mid-fluid-value(
    $propertyName,
    $min-value,
    $max-value,
    $small-laptop-screen,
    $medium-laptop-screen
  );
}

@mixin important-small-laptop-fluid-value(
  $propertyName,
  $min-value,
  $max-value
) {
  @include important-mid-fluid-value(
    $propertyName,
    $min-value,
    $max-value,
    $small-laptop-screen,
    $medium-laptop-screen
  );
}

%legend {
  padding-bottom: 1.25rem;
  margin-bottom: 0;
  font-size: 1.125rem;
  letter-spacing: -0.02em;
  color: $text-color;
  font-weight: 600;
}

@mixin scrollableComponent(
  $background-color: $hovered-bg,
  $thumb-color: lighten($hovered-text, 35),
  $track-color: $bg-color,
  $nonJumpingScrollbar: false
) {
  & {
    overflow-x: hidden;
    overflow-y: scroll;
    overflow-x: -moz-scrollbars-none;
    -ms-overflow-style: none;

    @if $nonJumpingScrollbar {
      box-sizing: border-box;
      padding-right: $scrollbar-width;

      &:hover {
        padding-right: 0;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 0;
    background-color: $background-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-color;
  }

  &:hover {
    &::-webkit-scrollbar {
      width: $scrollbar-width;
    }
  }
}

@mixin scrollStyling(
  $background-color: $hovered-bg,
  $thumb-color: lighten($hovered-text, 35),
  $track-color: $bg-color
) {
  &::-webkit-scrollbar {
    width: 0;
    background-color: $background-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-color;
  }

  &:hover {
    &::-webkit-scrollbar {
      width: 0.5rem;
    }
  }
}

%textSelection {
  &::selection {
    color: $selected-text;
    background-color: $accent;
  }
}

%textSelectionOnAccentBackground {
  &::selection {
    color: $accent;
    background-color: $selected-text;
  }
}

// To wedle zasad powinien być placeholder (bo nie potrzebuje żadnych argumentów do swojej modyfikacji),
// lecz placeholdery nie mogę używac media query, więc dlatego zostało to pyknięte jako mixins xD
@mixin singleCardWrapper() {
  width: 50%;
  @include transition();

  @media screen and (max-width: 1650px) {
    width: 60%;
  }

  @media screen and (max-width: 1550px) {
    width: 70%;
  }
}

// Dodawanie trzykropka dla tekstu, który nie mieści się w danej szerokości
@mixin textElipsis($maxTextLines: 6) {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  -webkit-line-clamp: $maxTextLines;
  -webkit-box-orient: vertical;
}
