.switch {
  &_container {
    display: flex;
    align-items: center;
    float: right;
  }

  &_description {
    margin-right: 0.75rem;
  }
}
