@import '../../styles/theme-variables';
@import '../../styles/animations.scss';
@import '../../styles/mixins.scss';

.bottom_section {
  button.k-button {
    position: relative;
    padding: 0.5rem 2rem;
    height: 100%;
    border: none;
    border-radius: 0.125rem;
    color: $selected-text;
    background-color: $success;
    background: $success;
    vertical-align: middle;
    font-weight: 500;
    outline: none;

    @include quickTransition();
    @include mid-fluid-value(padding-left, 1.5rem, 1.5rem);
    @include mid-fluid-value(padding-right, 1.5rem, 1.5rem);
    @include mid-fluid-value(font-size, 1.2rem, 1.5rem);

    @include small-laptop-fluid-value(font-size, 1rem, 1.2rem);

    &:hover {
      cursor: pointer;
    }

    &:active {
      transform: scale(0.95);
    }

    .danger_label {
      $label-size: 1.75rem;
      position: absolute;
      display: flex;
      right: calc(-#{$label-size} / 2.25);
      top: calc(-#{$label-size} / 2.25);
      width: $label-size;
      height: $label-size;
      font-size: 1.3rem;
      font-weight: 600;
      color: $selected-text;
      border-radius: 50%;
      background-color: $error;
      flex-direction: column;
      justify-content: center;
      align-content: center;

      animation: longShake 5000ms ease infinite;
    }
  }
}
