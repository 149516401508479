@import '../../../styles/theme-variables.scss';
@import '../../../styles/mixins.scss';

$triangle-size: 0.55em;
$tooltip-color: $info;

.tooltip_container {
  position: absolute;
  top: calc(100% + #{$triangle-size * 1.5});
  right: -0.25em;
  transform: translateX(40%);
  @include mid-fluid-value(font-size, 0.7rem, 1rem);

  .label {
    padding: 0.5em 0.75em;
    padding-left: 0.9em;
    background-color: var(--progress-bar-color);
    border-radius: 0.75em;
    font-size: inherit;
    color: white;
    user-select: none;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      left: 50%;
      transform: translateX(-50%);

      top: -$triangle-size * 0.9;

      border-right: $triangle-size solid transparent;
      border-left: $triangle-size solid transparent;
      border-bottom: $triangle-size solid var(--progress-bar-color);
    }
  }
}
