@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

// NOTE: te divy na początku selektorków są po to by nadpisywać niektóre style telerika swoją "precyzyjnością", a nie pykać !important xD

div .textarea {
  margin-top: 0.5em;
  padding: 0.75em;
  width: 100%;
  max-height: 30vh;
  border-radius: 0.75rem;
  background-color: $accent;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.14), inset -2px -1px 8px #1f7a6d,
    inset 4px 4px 8px rgba(0, 0, 0, 0.25),
    inset 10px 10px 60px rgba(0, 0, 0, 0.25) !important;
  font-weight: 400;
  color: $selected-text !important;
  @extend %textSelectionOnAccentBackground;
  @include scrollStyling($accent, darken($accent, 7), lighten($accent, 7));

  &::placeholder {
    color: rgba($selected-text, 0.7);
  }
}

div.content_container {
  position: relative;
  width: 50%;
  @include transition();

  &:focus-within,
  &_filled {
    width: 100%;
  }

  & > label {
    margin-bottom: 2.75rem;
  }
}

button.comment_button {
  position: absolute;
  right: 0;
  bottom: 0;
  @include quickTransition();
}
