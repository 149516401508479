@import './theme-variables.scss';
@import './mixins.scss';
@import './variables.scss';

// Nadpisanie styli wrzuconych przez edytor HTML (gdy jest w trybie fullscreen-a)
body {
  &.tox-fullscreen {
    position: relative !important;
    overflow: auto !important;

    .header {
      z-index: 0 !important;
    }
  }
}

.k-form {
  position: relative;
  max-width: $default-card-width;
  padding: 0;
  font-size: 12px;

  &:not(:first-of-type) {
    margin-top: 1.75rem;
    $strip-size: 2px;

    legend,
    h3 {
      padding-top: 1.5rem;
    }
  }

  fieldset {
    margin-bottom: 0.5rem;
    border: 0;

    legend {
      line-height: 1.5rem;
      @extend %legend;

      &:not(:first-of-type) {
        padding: 1rem 0 0.5rem;
      }
    }

    button {
      &.k-button {
        margin-top: 0.75rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  .section-card:not(.full-width-card) {
    textarea.k-textarea:not(.not-extendable) {
      width: 100%;
      @include transition(width);

      &:focus,
      &:not(:placeholder-shown) {
        width: 170% !important;

        @media screen and (max-width: 1550px) {
          width: 130% !important;
        }
      }
    }
  }

  // Stylowanie edytora html tak, by był na całą szerokość sekcji formularza
  .section-card:not(.full-width-card) {
    .tox {
      &.tox-tinymce {
        width: 209%;
        @include transition(width);

        @media screen and (max-width: 1650px) {
          width: 174.16%;
        }

        @media screen and (max-width: 1550px) {
          width: 149.28%;
        }
      }
    }
  }

  // Usuwanie dopisku o właścicielu edytora HTML (inicjatywa ADMIN-a)
  .tox-statusbar {
    &__branding {
      display: none;
    }
  }

  .k-form-field {
    position: relative;
    margin-bottom: 0.7rem;

    input,
    .k-widget,
    textarea {
      &:not(.k-switch):not(.k-window):not(.k-dialog):not(.k-radio) {
        width: 100%;
      }
    }

    & > span:first-of-type {
      padding-bottom: 0.3rem;
      color: rgba($text-color, 0.85);

      @include transition(color);
    }

    .k-numerictextbox {
      width: 100%;
    }

    .k-dropdown {
      ~ span {
        &:not([class]) {
          margin-top: -0.5rem;
        }
      }
    }

    input {
      &:focus,
      &:active {
        border-color: $accent;
      }
    }
  }

  .k-button:not(li) {
    margin-top: 0.75rem;
    font-weight: 500;
    @include laptop-fluid-value(padding-top, 0.595rem, 0.7rem);
    @include laptop-fluid-value(padding-bottom, 0.595rem, 0.7rem);
    @include laptop-fluid-value(padding-right, 1.275rem, 1.5rem);
    @include laptop-fluid-value(padding-left, 1.275rem, 1.5rem);

    & + button {
      margin-left: 2rem;
    }
  }

  .k-button-icontext {
    margin-bottom: 0.75rem;
  }

  .k-dropdown {
    margin-bottom: 0.75rem;
  }
}

.k-dropdown:not(.k-header) {
  margin-bottom: 1.25rem;
  width: 100%;
}

.k-grid-table {
  .k-button {
    margin-left: 0.5rem;
    margin-top: 0.2rem;
  }
}

.konvajs-content {
  margin-left: 4rem;
}

textarea {
  height: 6rem;
  min-height: 6rem;
  resize: vertical;
}

.k-popup,
.k-animation-container {
  font-family: $default-font;
}

.k-widget {
  .k-select {
    @include transition();
    background-image: unset !important;
    background-color: transparent !important;

    .k-icon {
      @include transition();
    }

    // Przyciski kasowania w multiselect-cie.
    &:not([aria-label='delete']):hover {
      background-color: $accent !important;

      .k-icon {
        color: $selected-text;
      }
    }
  }
}

// Zmiany stylistyczne inputów/labelek po focusie w danym 'form field-dzie'
.k-form {
  .k-form-field {
    &:focus-within,
    &:active {
      & > span:first-of-type {
        color: $accent;
        font-weight: 500;
      }

      .k-state-focused,
      .k-widget:focus-within:not(.k-grid-pager),
      .k-widget:active,
      .k-textarea:focus,
      .k-textarea:active {
        &,
        .k-numeric-wrap,
        .k-multiselect-wrap {
          border-color: $accent !important;
        }

        &:not(.k-grid):not(.k-multiselect) {
          .k-select {
            background-color: $accent !important;

            .k-icon {
              color: $selected-text;
            }
          }
        }
      }
    }
  }
}

textarea {
  height: 6rem;
  min-height: 6rem;
  resize: vertical;
}

.k-popup,
.k-animation-container {
  font-family: $default-font;
}

.k-widget {
  .k-select {
    @include transition();
    background-image: unset !important;
    background-color: transparent !important;

    .k-icon {
      @include transition();
    }

    // Przyciski kasowania w multiselect-cie.
    &:not([aria-label='delete']):hover {
      background-color: $accent !important;

      .k-icon {
        color: $selected-text;
      }
    }
  }
}

.k-filtercell-operator {
  .k-dropdown-wrap {
    width: auto;
  }
}

.k-widget {
  &:not(.k-switch):not(.k-window):not(.k-dialog):not(.k-dropdown-operator) {
    width: 100%;
  }
}

.max-input-width {
  .k-widget:not(.k-switch),
  .k-form-field {
    width: 100% !important;
  }

  input {
    width: 100% !important;
  }
}

input,
textarea,
.k-multiselect-wrap span:not([class]),
.k-radio-label {
  color: $black-color !important;
}

.k-switch {
  &.k-state-focused {
    .k-switch-container {
      box-shadow: $switch-box-shadow;
    }
  }
}

.k-grid-header {
  table {
    th {
      .k-link {
        display: flex !important;
        flex-direction: row-reverse;
        justify-content: flex-end;
        padding-right: 1.25rem !important;

        .k-icon {
          margin-left: 0 !important;
          margin-right: 0.5rem;
        }
      }
    }
  }

  .k-filtercell-operator {
    .k-widget {
      width: auto;
    }
  }

  .k-button-icon {
    @include transition();

    background-color: $accent !important;
    background-image: none !important;
    color: $selected-text;
    box-shadow: inset 0 0.125rem 0.125rem $accent;
  }

  .k-textbox {
    &:focus,
    &:active {
      border-color: $accent;
    }
  }
}

// Wszystkie kontrolki formularzy (służące do wpisywania zawartości)
form {
  .k-multiselect-wrap,
  .k-picker-wrap,
  .k-dropdown-wrap,
  .k-textbox,
  .k-numeric-wrap,
  .k-autocomplete,
  textarea {
    box-shadow: 0 5px 14px -4px rgba(0, 0, 0, 0.15) !important;
  }
}

// Stylizacja toggle-a
.k-switch {
  border: 0 solid rgba($accent, 0.5);
  @include transition(border-color);

  &:hover {
    border-color: $accent-light;
  }

  .k-switch-handle {
    background-color: $base-bg !important;

    @include transition();
  }

  // Nie są tutaj style 'zbijane' w całość, do momentu ostatecznej akceptacji toggle-a xD
  .k-switch-container {
    background-color: $hovered-bg;
  }

  &:hover {
    .k-switch-container {
      background-color: $hovered-bg;
    }
  }

  &-on {
    .k-switch-container {
      background-color: $accent;
    }

    &:hover {
      .k-switch-container {
        background-color: mix($hovered-bg, $accent, $weight: 60%);
      }
    }
  }

  &-off {
    &:hover {
      .k-switch-container {
        background-color: mix($hovered-bg, $accent, $weight: 50%);
      }
    }
  }
}

// Stylowanie "x" wylistowanych przedmiotów multiselect-a
.k-multiselect {
  .k-button {
    &:focus,
    &:focus-within,
    &:hover {
      .k-icon {
        color: $selected-text;
        background-color: $accent;
      }
    }
  }
}

// Zabezpieczenie przed używaniem, przez svg, koloru tekstu (który nierzadko jest czarny xD), jako koloru ikonki
svg {
  color: $accent;
}

// Animacja 'podskakujacego' przycisku przy kliknięciu
button {
  @include quickTransition(transform);

  &:active {
    transform: scale(0.95);
  }
}

// Stylowanie niezaznaczonych radio buttonów
.k-radio-label {
  &::before {
    @include quickTransition(border);
  }

  &.k-state-invalid {
    &::before {
      border: 0.125rem solid $error;
    }
  }

  &:active {
    &.k-state-invalid {
      &::before {
        border: 0.175rem solid $accent;
      }
    }
  }
}

// Style odnoszace sie do telerikowego kalendarza
.k-datetime-container {
  .k-calendar {
    .k-content {
      .k-today {
        font-weight: 600;
        background-color: rgba($success, 0.15);
      }
    }
  }
}

// Style odnoszące się do wyszukanej frazy z telerikowego combobox-a
.k-list-container {
  .k-popup {
    .k-list-scroller {
      .k-list {
        .k-state-focused {
          &:not(.k-state-selected) {
            background-color: rgba($success, 0.15);
          }
        }
      }
    }
  }
}

// Utility klasy dla telerikowych przycisków
.telerik-background {
  color: $selected-text !important;

  &_warning {
    background: $warning !important;
  }

  &_info {
    background: $info !important;
  }

  &_accent {
    background: $accent !important;
  }

  &_success {
    background: $success !important;
  }

  &_error {
    background: $error !important;
  }

  &_dark_blue {
    background: $series-e !important;
  }
}

// Style do zarządzania rozmiarem telerikowej czcionki
.k-form {
  input {
    @include important-laptop-fluid-value(font-size, 0.8rem, 0.875rem);
  }
}

// Style do scrollowania w górę/dół telerikowego grida
.k-grid-content {
  &.k-virtual-content {
    overflow-y: scroll;
    // 150px to jest wysokość tekstów w kolumnach oraz dolnej paginacji
    max-height: calc(100vh - 225px);
  }
}

// Przyklejenie przycisku resetu filtrów, do lewego górnego boku grida (tak by wystawał z jego lewej strony)
// Oraz jego stylowanie aby przy jego wyłączeniu, był szary
.grid_container {
  position: relative;

  .fox-clear-button {
    position: absolute;
    top: 0;
    left: calc(-1.75rem);
    @include laptop-fluid-value(margin-left, -0.3125rem, -0.6875rem);
    @include quickTransition();

    &.k-state-disabled {
      opacity: 0;
    }
  }
}

// Style dla trzykropka (na gridzie), który pojawia się, gdy tekst w headerze nie mieści się w kolumnie
.grid_container {
  .k-grid-header {
    .k-link {
      & > span {
        @include textElipsis(1);
      }
    }
  }
}

// Stylowanie przycisków
.k-button {
  &.button {
    color: $selected-text !important;

    &_success {
      color: $selected-text !important;
      background: $success !important;
    }

    &_info {
      background: $info !important;
    }

    &_error {
      background: $error !important;
    }

    &_warning {
      background: $warning !important;
    }

    &_dark {
      background: $text-color !important;
    }

    &_dark-blue {
      background: $series-e !important;
    }

    &_custom-green {
      background: $custom-green !important;
    }
  }
}

// Stylowanie okna dialogowego (modala)
.k-dialog {
  min-width: 300px;

  .k-dialog-titlebar {
    .k-dialog-title {
      margin-right: 1rem;
      padding: 0.15rem 0;
      font-weight: 500;
      font-size: 1.15rem;
    }

    .k-dialog-close {
      padding: 0 1.25rem;
      margin: auto !important;
      align-self: center;

      .k-icon {
        @include quickTransition();
      }

      &:hover {
        .k-icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .k-window-content,
  .k-dialog-content {
    padding: 1.25rem 1.15rem;

    p {
      margin: auto;
      font-size: 1rem;
      color: $text-color;
    }
  }

  .k-dialog-buttongroup {
    .k-button {
      margin-top: 0;
      padding: 1rem 0;
      font-size: 1rem;
      @include quickTransition();

      &:active,
      &:focus {
        transform: scale(1);
      }

      &:not(.k-primary) {
        $button-color: $text-color;
        color: $button-color;

        &:hover,
        &:active,
        &:focus {
          color: $selected-text;
          background: $button-color;
          // Telerikowy box-shadow dla focusa przycisku
          box-shadow: inset 0 0 0 2px rgba($button-color, 0.25);
        }
      }
    }
  }

  .invalid-message {
    margin-top: 0.425em;
    text-align: right;
    font-size: 0.85rem;
    color: rgba($error, 0.85);
  }
}

$dialog-colors: (
  'red': $error,
  'orange': $warning,
  'blue': $info,
  'custom_green': $custom-green,
);

@each $colorName, $colorValue in $dialog-colors {
  .k-dialog-wrapper {
    &.#{$colorName} {
      .k-window-titlebar {
        background-color: $colorValue;
      }

      .k-dialog-buttongroup {
        .k-button {
          &.k-primary {
            color: $colorValue;

            &:hover,
            &:active,
            &:focus {
              color: $selected-text;
              background: $colorValue;
            }
          }
        }
      }
    }

    &.bigger {
      .k-widget:not(.k-switch) {
        min-width: 650px;
      }
    }
  }
}

// Stylowanie "przyklejania się" kolumn
.k-grid-content-sticky {
  left: 0;
  border-right: 1px solid mix($black-color, $selected-text, 8%) !important;
}

.k-grid-table {
  .k-master-row {
    &:hover {
      td {
        background-color: lighten($hovered-bg, 10) !important;
      }
    }

    &:nth-of-type(even) {
      td {
        background-color: #f5f5f5;
      }
    }
  }
}

// Zmiana domyślnych zaokrągleń rogów oraz rozmiarów telerikowych przycisków (z wyłączeniem ikonek, będących przyciskami)
.k-button:not(.k-button-icon) {
  padding: 0.35rem 0.75rem;
}

// Ukrywanie zestackowanych nazw kolumn podczas grupowania na gridzie
.k-grid {
  tr.k-grouping-row {
    td:not([style*='left: 0px; right: 0px;']) {
      display: none;
    }
  }
}

// Podświetlanie się wiersza przy zmianie jego wartości
.k-grid {
  tbody {
    tr {
      &,
      td {
        @include quickTransition();
      }

      &.highlight {
        $colors: (
          'green': $success,
          'red': $error,
        );

        @each $color-name, $color-value in $colors {
          &-#{$color-name} {
            $color: mix($color-value, $selected-text, 30);

            &,
            &:hover,
            &:hover > td,
            & > td {
              background-color: $color !important;
              border-color: $color !important;
            }
          }
        }
      }
    }
  }
}

// Zmniejszenie czcionki wewnątrz grida o 10%
.k-widget {
  font-size: 14px * 0.9;

  &.k-menu {
    font-size: 0.95rem;
  }

  &.k-switch {
    font-size: 10px * 0.9;
  }
}

// Naprawa zniekształconych ikonek countera dla inputu numerycznego telerikowego grida (były tak śmisznie przesunięte w lewo)
.k-grid .k-grid-header table th .k-select .k-link .k-icon {
  margin-right: 0;
}

// Stylowanie górnej 'belki' grida oraz odległości elementów (przycisków/linków), które znajdują się w nim
.k-grid .k-grid-toolbar {
  padding: 0.5rem 0.75rem;

  & > * {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

// Stylowanie przycisku pobierania załączników (u klienta -> /report-company)
.k-upload {
  .k-button.k-upload-button {
    margin-top: 0;

    input {
      height: 100%;
    }
  }
}

// Stylowanie komunikatów niepoprawnego uzupełnienia danego pola formularza
.k-invalid-message,
.k-form .k-form-field span.k-invalid-message {
  position: absolute;
  right: 0;
  margin-top: 0.4rem;
  padding: 0.25rem 0.5rem;
  max-width: 40%;
  background-color: $error;
  border-radius: 15px;
  color: $selected-text;
  text-align: center;
  font-size: 0.7rem;
  z-index: 3;
}

.k-form-field {
  input.k-radio {
    margin-right: 0.15rem;
    @include quickTransition();

    &:not(:first-of-type) {
      margin-left: 0.75rem;
    }

    &::before {
      background-color: $selected-text;
    }

    &.k-state-invalid {
      border: 4px solid rgba($error, 0.85);
    }
  }
}

// Poprawa telerikowych kalkujacji dla przycisku "x" wystepujacego przy dropdownach
body .k-combobox .k-dropdown-wrap .k-clear-value {
  top: 2.5px;
  right: 2.25rem;
}

// Dostosowanie wysokości modali, tak by nie wychodziły poza ekran
// NOTE: 90px to jest wysokość belki dialogu ORAZ przycisków w stopce modala
.k-dialog-content {
  max-height: calc(90vh - 90px);
  overflow: auto;
}

// Naprawienie buga z wnętrzenm kalendarza, który nie rozpycha się na szerokość wrappera
.k-datetime-calendar-wrap {
  .k-content.k-scrollable {
    width: 285px;

    & > table:first-child {
      width: 100%;
    }

    // Szerokość komórek dekadd/lat/miesięcy
    .k-link {
      width: 100%;
    }
  }
}

// TODO Vorbert -> usunąć buga ze zleceniami transportowymi i display:block dla każdego spana
.k-form-field .k-required {
  display: inline-block !important;
}

// Stylowanie większego odstępu dla inputów wyświetlających errora
.k-state-invalid--large {
  margin-bottom: 2.25rem !important;
}
