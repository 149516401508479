@import '../../styles/theme-variables.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$button-color: $series-e;
$colors: (
  'red': $error,
  'orange': $warning,
  'blue': $info,
  'dark-blue': $series-e,
  'green': $success,
  'dark-green': $accent,
  'custom-green': $custom-green,
  'grey': $hovered-bg,
  'dark': $text-color,
);

.button {
  color: $selected-text;
  border-radius: 1.25rem;
  border: 0.125rem solid $button-color;
  padding: 0.25em 0.35em;
  padding-right: 0.75em;
  font-weight: 500;
  font-size: 1.05rem;
  outline: none;
  background-color: $button-color;
  filter: grayscale(0);
  box-shadow: 0.125rem 0.25rem 0.75rem -0.125rem rgba(23, 23, 23, 0.7);
  @include transition();
  @include flex(row, center, center);
  @include mid-fluid-value(font-size, 0.9rem, 1.15rem);

  &:not(:last-of-type) {
    @include mid-fluid-value(margin-right, 0.75rem, 1.25rem);
  }

  &:disabled {
    filter: grayscale(100%);
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0.25rem 0.5rem 1.5rem -0.25rem rgba(23, 23, 23, 0.85);
    transform: translateY(-0.125rem);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0.25rem 0.5rem 1.5rem -0.5rem rgba(23, 23, 23, 0.85);
  }

  .icon {
    color: $button-color;
    @include centerAbsolutely();

    &_container {
      position: relative;
      display: inline-block;
      margin-right: 0.5em;
      width: 1.75em;
      height: 1.75em;
      background-color: $selected-text;
      border-radius: 50%;

      .loader {
        $border-width: 0.175em;
        $border-color: $accent;

        width: 100%;
        height: 100%;
        background-color: rgba($selected-text, 0.5);
        border-radius: 50%;
        border: $border-width solid $border-color;
        border-color: $border-color transparent $border-color transparent;
        animation: rotatingCircle 1.2s linear infinite;
        @include centerAbsolutely();
      }
    }
  }

  @each $colorName, $colorValue in $colors {
    &_#{$colorName} {
      background: $colorValue;
      border-color: $colorValue;

      .icon {
        color: $colorValue;
      }
    }
  }
}

@keyframes rotatingCircle {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
