@import '../../styles/theme-variables.scss';
@import '../../styles/animations.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$badge-border-radius: 1rem;
$badge-animation-duration: 350ms;
$badge-animation-appearing-duration: 500ms;
$badge-animation-appearing-delay: 200ms;

$classProperties: (
  'blue': $info,
  'red': $error,
  'orange': $warning,
  'blue_dark': $series-e,
  'green': $success,
  'green_dark': $accent,
  'green_custom': $custom-green,
  'grey_dark': $base-text,
  'yellow': $series-b,
);

.badge {
  --badge-background-color: #{$info};
  --badge-text-color: #{$selected-text};

  position: relative;
  height: fit-content;
  width: fit-content;
  background-color: transparent;
  border-radius: $badge-border-radius;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  color: transparent;
  animation: badgeAppearing $badge-animation-duration
    $badge-animation-appearing-duration + $badge-animation-appearing-delay ease
    forwards;

  @include quickTransition();
  @include mid-fluid-value(padding-top, 0.25rem, 0.35rem);
  @include mid-fluid-value(padding-bottom, 0.25rem, 0.35rem);
  @include mid-fluid-value(padding-left, 0.75rem, 1rem);
  @include mid-fluid-value(padding-right, 0.75rem, 1rem);

  &:not(.smaller) {
    @extend %normalLaptopFontSize;

    @media screen and (min-width: $medium-laptop-screen) and (max-width: $big-laptop-screen) {
      padding: calc(
          0.15rem + (16 * 0.2) * ((100vw - #{$medium-laptop-screen}) / 240)
        )
        calc(0.75rem + (16 * 0.25) * ((100vw - #{$medium-laptop-screen}) / 240));
    }

    @media screen and (max-width: $big-laptop-screen) {
      line-height: 1.25rem;
    }
  }

  &.smaller {
    padding: 0.25rem 0.7rem;
    font-size: 0.8rem;
  }

  &.offset_top {
    margin-top: 0.25rem;
  }

  &::before {
    content: '';
    background-color: var(--badge-background-color);
    border-radius: $badge-border-radius;
    animation: badgeBackgroundAppearing $badge-animation-appearing-duration
      $badge-animation-appearing-delay ease-in-out forwards;
    z-index: -1;
    @include centerAbsolutely();
  }

  &.inverted {
    &::before {
      background-color: $selected-text;
    }
  }

  &.opaque {
    font-weight: 600;
  }

  &.inverted {
    font-weight: 600;
    animation: invertedBadgeAppearing $badge-animation-duration
      $badge-animation-appearing-duration + $badge-animation-appearing-delay
      ease forwards;
  }

  &.invisible {
    animation: badgeHiding $badge-animation-duration ease forwards;
  }

  &.with_border {
    background-color: transparent;
    color: transparent;
    border: 0.125rem solid transparent;
    animation: borderBadgeAppearing $badge-animation-duration
      $badge-animation-appearing-duration + $badge-animation-appearing-delay
      ease forwards;
    // TODO Vorbert -> dorobić mixinsy dla fluid value, nie zależnych od jednostek
    @include mid-fluid-value(
      padding-top,
      0.25rem - 0.125rem,
      0.35rem - 0.125rem
    );
    @include mid-fluid-value(
      padding-bottom,
      0.25rem - 0.125rem,
      0.35rem - 0.125rem
    );

    &::before {
      animation: unset;
    }
  }

  &.clickable {
    user-select: none;
    animation: clickableBadgeAppearing $badge-animation-duration
      $badge-animation-appearing-duration + $badge-animation-appearing-delay
      ease forwards;

    &:hover {
      cursor: pointer;
      transform: translateY(-0.125rem) scale(1.1);
      box-shadow: 0 0.25rem 1.25rem -0.15rem rgba(23, 23, 23, 0.45);
    }

    &:active {
      transform: translateY(0) scale(0.95);
      box-shadow: 0 0.25rem 0.75rem -0.15rem rgba(23, 23, 23, 0.35);
    }
  }
}

@each $name, $value in $classProperties {
  .#{$name} {
    --badge-background-color: #{$value};

    &.opaque {
      --badge-background-color: #{mix($value, $selected-text, 30%)};
      --badge-text-color: #{$value};
    }
  }
}

.grey {
  --badge-text-color: #{$text-color};
  --badge-background-color: #{$hovered-bg};

  &.opaque {
    --badge-background-color: #{mix($text-color, $selected-text, 30%)};
    --badge-text-color: #{$text-color};
  }
}

.red {
  &.with_border {
    --badge-background-color: #{mix($error, $selected-text, 70%)};
  }
}

// W razie zmiany schematów kolorstycznych, dać klasę z domyślnym kolorem, jako pierwszą pod tym komentarzem.
