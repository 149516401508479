@import '../../../styles/theme-variables.scss';
@import '../../../styles/mixins.scss';

$progress-bar-border-radius: 2.5rem;

.progress_bar_container {
  position: relative;
  width: 100%;
  min-width: 150px;
  height: 0.5rem;
  background-color: $selected-text;
  border-radius: $progress-bar-border-radius;
  box-sizing: border-box;
}
