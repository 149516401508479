@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

$button-font-size: 0.9rem;

.button {
  padding: 0.5em 0.85em;
  width: fit-content;
  background-color: transparent;
  color: $selected-text;
  border: none;
  border-radius: 3em;
  font-size: $button-font-size;
  font-weight: 600;
  @include quickTransition();
  @include laptop-fluid-value(font-size, 0.75rem, $button-font-size);

  .icon {
    margin-left: -1em;
    width: 1em !important;
    height: 1em;
    opacity: 0;
    @include transition();
  }

  &:hover {
    cursor: pointer;

    .icon {
      margin-left: 0.5rem;
      opacity: 1;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: $accent;
    background-color: $selected-text;
    outline: none;
  }
}
