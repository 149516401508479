@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

.attachment {
  width: 100%;
  @include mid-fluid-value(font-size, 0.75rem, 0.9rem);

  &:first-of-type {
    margin-top: 1.25em;
  }

  &:not(:first-of-type) {
    margin-top: 0.75em;
  }

  &_icon {
    font-size: 1.25em;
    @include transition();

    &_container {
      justify-self: flex-end;
    }

    &:not(:last-of-type) {
      margin-right: 0.75em;
    }

    &_remove {
      color: $error;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.1) rotate(360deg);
    }

    &:active,
    &:focus {
      transform: scale(0.8) rotate(360deg);
    }
  }

  &_name {
    flex: 1;
  }
}
