// Zmniejszenie czionki aplikacji o 10%
html {
  font-size: 16px * 0.9;
}

body {
  background-color: $bg-color;

  // Dla firefox-a
  scrollbar-color: $hovered-bg;

  // Style scrollbar-u
  &::-webkit-scrollbar {
    width: 0.75rem;
    background-color: $hovered-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lighten($hovered-text, 35);
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px -2px rgba($text-color, 0.5);
    -webkit-box-shadow: inset 0 0 10px -2px rgba($text-color, 0.5);
    background-color: $selected-text;
  }
}

.App {
  font-family: $default-font;
  background-color: $bg-color;
  max-width: 100vw;
  overflow: hidden;

  * {
    @extend %textSelection;
  }

  svg {
    z-index: 1;
    fill: currentColor;
  }
}

.fs_xlarge {
  font-size: $font-size-xlarge;
}

.fs_large {
  font-size: $font-size-large;
}

.fs_medium {
  font-size: $font-size-medium;
}

.fs_small {
  font-size: $font-size-small;
}

.fs_xsmall {
  font-size: $font-size-xsmall;
}

a {
  font-weight: 500;
  text-decoration: none;

  &.link {
    position: relative;
    display: inline-block;
    width: fit-content;
    color: $accent;
    @include transition();

    &::before {
      $strip-size: 0.17rem;

      content: '';
      position: absolute;
      left: 0;
      bottom: $strip-size * -1;
      width: 15%;
      height: $strip-size;
      background-color: $accent;
      @include transition();
    }

    &:hover,
    &:focus,
    &:active {
      &::before {
        width: 100%;
      }
    }

    &-grid {
      color: $text-color;

      &::before {
        width: 35%;
        height: 0.15rem;
        bottom: 0;
        background-color: rgba($text-color, 0.65);
      }

      &:hover {
        color: $accent;

        &::before {
          width: 100%;
          background-color: $accent;
        }
      }
    }

    &.white {
      color: $selected-text;

      &::before {
        background-color: $selected-text;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text-color;
}

h2 {
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 1.75rem;
  font-size: 30px;
  line-height: 37px;
  letter-spacing: -0.02em;
  color: $text-color;
}

h3 {
  @extend %legend;
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
}

.fox_spinner {
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 20;
  }

  &::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($text-color, 0.15);
  }

  &::after {
    height: $spinner-size;
    width: $spinner-size;
    transform-origin: center center;
    border: $spinner-size * 0.15 solid transparent;
    border-top-color: $accent;
    border-bottom-color: $accent;
    border-radius: 50%;
    animation: spinning 0.8s linear infinite;
    @include centerAbsolutely();
  }
}

.avatar_placeholder {
  width: 4rem;
  height: 4rem;
  background-color: $accent;
  border-radius: 50%;

  &::before {
    content: '';
  }
}

$labelColors: (
  'blue': $info,
  'orange': $warning,
  'green': $custom-green,
);

.label {
  @each $colorName, $colorValue in $labelColors {
    &_#{$colorName} {
      color: $colorValue;
    }
  }

  &_bold {
    font-weight: 600;
  }
}
