@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$horizontal-offset: $form-section-icon-font-size + 2 * $form-section-icon-offset;

.form_section_container {
  position: relative;
  padding: 1.5rem $horizontal-offset 2rem;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  box-sizing: border-box;

  @include transition(box-shadow);
  @include laptop-fluid-value(
    padding-right,
    $horizontal-offset * 0.75,
    $horizontal-offset
  );

  &:focus-within {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.07);
  }

  &.single_card {
    width: 100%;
  }

  .single_card_wrapper {
    @include singleCardWrapper();
  }

  &.less_padding_left {
    padding-left: 2rem;
  }

  &.less_padding_right {
    padding-right: 2rem;
  }

  &.full_content_width {
    padding-right: 2.5rem;

    .single_card_wrapper {
      width: 100%;
    }
  }
}
