@import 'styles/variables.scss';

.row {
  & + & {
    margin-top: 1.25rem;
  }

  &_small {
    & + & {
      margin-top: 0.75rem;
    }
  }
}

.container {
  min-width: 650px;
}

.icon {
  color: $accent !important;
}
