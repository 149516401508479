@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$section-wrapper-spacing: 3rem;

.section_wrapper {
  @include flex(row);

  & > div {
    &:first-of-type,
    &:nth-of-type(3) {
      width: $form-single-card-size;
    }

    &:nth-of-type(2) {
      width: 14%;
    }
  }

  &.column_on_break {
    @media screen and (max-width: $column-break-width) {
      flex-direction: column;

      & > div {
        width: 100%;

        & > div {
          @include singleCardWrapper();
        }
      }
    }
  }

  &:not(:first-of-type) {
    @include laptop-fluid-value(margin-top, 2.25rem, $section-wrapper-spacing);
  }

  &:last-of-type {
    margin-bottom: 0.75rem;
  }

  &.bottom_space {
    @include important-laptop-fluid-value(
      margin-bottom,
      $section-wrapper-spacing * 0.75 * 0.75,
      $section-wrapper-spacing
    );
  }
}
