@import '../../styles/mixins.scss';
@import '../../styles/theme-variables.scss';

div.content {
  margin-top: 0.25em;
  margin-left: 0.75em;
  flex: 6;
  box-sizing: border-box;
  justify-content: start;

  div.label {
    align-self: flex-start;
  }

  .status_label {
    margin-left: 0.25em;
    margin-top: 0.25em;
    color: rgba(darken($selected-text, 20), 0.5);
    @include important-mid-fluid-value(font-size, 0.8625rem, 1.15rem);
    @include transition();
  }

  .date {
    margin-top: 0.5em;
    font-weight: 500;
    color: rgba($selected-text, 0.7);
    @include important-mid-fluid-value(font-size, 0.75rem, 0.85rem);
  }

  .children_container {
    @include mid-fluid-value(margin-top, 0.5em, 0.75em);
    @include mid-fluid-value(margin-bottom, 0.5em, 0.75em);
  }

  .date,
  .children_container {
    @include mid-fluid-value(margin-left, 0.15em, 0.85em);
  }
}
