@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.user_information_form_legend {
  ~ button {
    &:first-of-type {
      display: block;
      margin: 1.5rem 0 0;
    }
  }

  ~ div {
    margin-bottom: 0.75rem;
  }
}

.avatar_container {
  position: relative;
  width: fit-content;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
  }

  &:hover {
    cursor: pointer;

    .avatar_edit_button {
      opacity: 1;
    }
  }

  .avatar_edit_button {
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($dark-color, 0.7);
    border-radius: 50%;
    opacity: 0;
    @include transition(opacity);

    &::after {
      content: attr(data-label);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      text-align: center;
      font-size: 1.25rem;
      color: $light-color;
      font-weight: 500;
    }
  }
}
