@import '../../styles/theme-variables.scss';
@import '../../styles/variables.scss';

$colorClasses: (
  'red': $error,
  'orange': $warning,
  'blue': $info,
  'darkBlue': $series-e,
  'green': $success,
  'darkGreen': $accent,
  'customGreen': $custom-green,
  'dark': $text-color,
  'yellow': $series-b,
  'grey': $text-color,
);

.counter {
  padding: 0.2em 0.75em;
  margin-right: 0.5rem;
  border-radius: 1.75em;
  font-size: 0.9em;
  font-weight: 700;
  box-shadow: 0 0.15em 1.25em 0.15em rgba(23, 23, 23, 0.15);

  @each $name, $value in $colorClasses {
    &.#{$name} {
      color: $value;
      background-color: mix($value, $selected-text, 30);
    }
  }
}
