@import '../../styles/theme-variables.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$padding-vertical: 2.5rem;
$padding-horizontal: 3rem;
$extra-offset: 3rem;

.inner_content_wrapper {
  margin-right: auto;
  margin-left: 0;
  width: fit-content;
  min-width: $default-card-width;
  max-width: calc(100% - 1.5rem - #{$content-wrapper-vertical-offset});
  border-radius: $border-radius * 4;
  background-color: $bg-color;
  border: 0.125rem solid rgba($text-color, 0.2);
  box-sizing: border-box;

  @include laptop-fluid-value(padding-top, 1.875rem, $padding-vertical);
  @include laptop-fluid-value(padding-bottom, 1.875rem, $padding-vertical);
  @include laptop-fluid-value(padding-right, 2.25rem, $padding-horizontal);
  @include laptop-fluid-value(padding-left, 2.25rem, $padding-horizontal);
  @include box-shadow(0, 4px, 32px, -10px);
  @include transition();

  &.full_width {
    width: 100%;
  }
}
