@import '../../styles/variables.scss';
@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

.bottom_section {
  background-color: $accent;
  box-shadow: 0 -8px 8px -2px rgba(0, 0, 0, 0.25);
  color: $selected-text;
  box-sizing: border-box;

  @include mid-fluid-value(padding-top, 1.5rem, 2rem);
  @include mid-fluid-value(padding-right, 3rem, 4rem);
  @include mid-fluid-value(padding-bottom, 1.5rem, 2rem);
  @include mid-fluid-value(padding-left, 1rem, 1.5rem);

  @include small-laptop-fluid-value(padding-right, 1.5rem, 3rem);

  &_item {
    flex: 1;

    &.button_section {
      & > *,
      button {
        width: 100%;
      }
    }
  }

  svg {
    color: $selected-text;
  }

  span {
    display: block;

    @include quickTransition();
    @include mid-fluid-value(font-size, 0.75rem, 0.8rem);
  }

  &__offer {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: $medium-laptop-screen) {
  }
}

.label {
  margin-top: 0.25rem;

  @include quickTransition(font-size);
  @include mid-fluid-value(font-size, 1.15rem, 1.5rem);
}
