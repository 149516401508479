@import '../../styles/theme-variables.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.clickable_panel {
  position: relative;
  min-width: 175px;
  outline: none;
  cursor: pointer;
  text-align: right;
  align-self: flex-end;
  @include transition(background-color);
  @include flex(row);

  &:not(.no_padding) {
    width: 100%;

    & > * {
      &:first-child {
        padding: $menu-item-offset;
        padding-left: 0;
        width: 100%;
        font-weight: 500;
        background-color: transparent;
        text-align: left;
        @include flex(row, flex-start, center);
        @include transition(all);

        &:hover {
          background-color: $hovered-bg;
        }
      }
    }
  }

  &:hover {
    background-color: $hovered-bg;
    color: $hovered-text;
  }
}
