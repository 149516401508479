@import '../../styles/theme-variables.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.comment_button {
  background: $custom-green !important;
  color: $selected-text !important;
  font-weight: 600;
  @include quickTransition();

  &:disabled {
    background: $accent !important;
    color: rgba(darken($selected-text, 20), 0.5) !important;
  }
}
