@import '../../../styles/theme-variables.scss';
@import '../../../styles/mixins.scss';

// TODO Vorbert -> wymyślić sposób, dzięki któremu podpinanie tej palety kolorów byłoby uniwersalne ;o
$colors: (
  'red': $error,
  'orange': $warning,
  'blue': $info,
  'dark-blue': $series-e,
  'green': $success,
  'dark-green': $accent,
  'custom-green': $custom-green,
  'dark': $text-color,
);
$button-border-radius: 0.35em;
$buttom-vertical-offset: 0.65em;
$button-horizontal-offset: 0.75rem;

.dropdown_button {
  // TODO Vorbert -> zrobić mixinsa na wrzucanie zmiennych scss-owych do tych css-owych :D
  --button-horizontal-offset: #{$button-horizontal-offset};

  padding: $buttom-vertical-offset $button-horizontal-offset;
  background-color: transparent;
  border: none;
  outline: none;
  // NOTE kolorek jest z importantem, bo telerik próbuje tutaj nadpisywać swoimi zasadami
  color: $selected-text !important;
  text-align: left;
  // NOTE font-size jest brany z Dropdown.module.scss, żeby wszystkie rozmiary można było zmienić w jednym miejscu (również te dotyczące samego dropdowna)
  font-size: inherit;
  font-weight: 500;
  white-space: nowrap;
  letter-spacing: -0.02em;
  @include quickTransition();

  &:first-child {
    border-top-left-radius: $button-border-radius;
    border-top-right-radius: $button-border-radius;
  }

  &:last-child {
    border-bottom-left-radius: $button-border-radius;
    border-bottom-right-radius: $button-border-radius;
  }

  &:active,
  &:focus {
    transform: scale(1);
  }

  &:hover {
    cursor: pointer;
    background-color: darken($selected-text, 10);

    svg {
      transform: rotate(360deg);
    }
  }

  @each $colorName, $colorValue in $colors {
    &_#{$colorName} {
      $hoverBackgroundColor: darken($colorValue, 7);

      background-color: $colorValue;

      &:not(:last-child) {
        border-bottom: 0.125em solid $hoverBackgroundColor;
      }

      &:hover {
        background-color: $hoverBackgroundColor;
        border-color: $hoverBackgroundColor;
      }
    }
  }
}
