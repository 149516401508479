@import '../../../styles/theme-variables.scss';
@import '../../../styles/mixins.scss';

.icon {
  color: $selected-text;
  font-size: inherit;
  @include transition();

  &_container {
    margin-right: var(--button-horizontal-offset);
    width: 1em;
  }
}
