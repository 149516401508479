@import '../../../styles/theme-variables.scss';
@import '../../../styles/variables.scss';

$progress-bar-border-radius: 2.5rem;
$colors: (
  'red': mix($error, $selected-text, 70),
  'orange': mix($warning, $selected-text, 70),
  'blue': $info,
  'green': mix($custom-green, $selected-text, 70),
);

.filling {
  --progress-bar-color: mix($info, $selected-text, 70);

  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--progress-bar-color);
  height: 100%;
  width: 50%;
  border-radius: $progress-bar-border-radius;
  transition: all 350ms ease;

  @each $colorName, $colorValue in $colors {
    &_#{$colorName} {
      --progress-bar-color: #{$colorValue};
    }
  }
}
