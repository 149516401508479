@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

$breadcrumb-item-offset: 0.75rem;

.breadcrumbs_container {
  padding: 0 1.5rem 1.25rem 0;
  max-width: 100%;
  background-color: transparent;
  box-sizing: border-box;
  @include laptop-fluid-value(font-size, 0.9rem, 1rem);

  .link_container {
    width: auto;

    &:first-of-type {
      svg {
        display: none;
      }
    }

    &:not(:first-of-type) {
      margin-left: $breadcrumb-item-offset;

      svg {
        margin-right: $breadcrumb-item-offset;
      }
    }

    &:hover,
    &.active {
      &:not(:last-of-type) > a,
      svg {
        color: rgba($accent, 0.85);
      }
    }

    &:last-of-type {
      & > a,
      svg {
        color: $accent;
      }
    }

    & > a {
      display: block;
      position: relative;
      color: rgba($text-color, 0.7);
      font-weight: 500;
      @include quickTransition();
      @include laptop-fluid-value(font-size, 0.9rem, 1rem);
    }

    svg {
      color: rgba($text-color, 0.7);
    }
  }
}
