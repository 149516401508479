@import '../../styles/theme-variables.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.section_icon {
  position: absolute;
  left: $form-section-icon-offset;
  width: $form-section-icon-font-size !important;
  height: $form-section-icon-font-size !important;

  &_invalid {
    color: $error;
  }
}

.title {
  &_container {
    padding-bottom: 0.75rem;
    font-size: $form-section-font-size;
    color: $accent;
    font-weight: 500;
    @include flex(row, flex-start, center);
  }

  & {
    position: relative;
  }

  &_invalid {
    &,
    .title_star {
      color: $error;
    }

    &_message {
      top: calc(100% - 0.75em);
    }
  }
}
