@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

.footer {
  position: relative;
  padding: 1rem 2rem;
  width: 100vw;
  min-height: 2rem;
  background-color: $accent;
  color: $selected-text;
  font-size: 0.75rem;
  z-index: 1;

  @include flex(row, space-evenly, stretch);

  a {
    color: $selected-text;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }
  }
}

.column {
  flex: 1;
  height: inherit;
  margin: 0;
  align-self: center;

  @include flex();

  &.left {
    order: 1;

    .content {
      align-self: flex-start;

      &_line {
        display: block;
        line-height: 1rem;
      }
    }
  }

  &.center {
    order: 2;

    .content {
      align-self: center;
    }
  }

  &.right {
    order: 3;

    .content {
      align-self: flex-end;
    }
  }
}
