div.input_wrapper {
  & > * {
    &:first-of-type {
      width: 100%;
    }

    &:last-child {
      width: 8rem !important;
    }
  }
}
