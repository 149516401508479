.circle {
  fill: transparent;
  stroke: rgba(245, 245, 245, 0.7);
}

.progress_ring {
  position: relative;
}

.circle,
.progress_ring {
  transition: all 250ms linear;
}
