@import '../styles/mixins.scss';

// TODO Vorbert -> ogarnac sposób by zczytywać aktualną wysokość headera

.layout_row {
  position: relative;
  min-height: calc(100vh - 74px);
  @include transition(min-height);

  &.shrinked {
    min-height: calc(100vh - 54px);
  }
}
