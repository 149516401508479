@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

.price-section-container {
  .k-textbox-container {
    position: relative;

    .k-widget.k-numerictextbox {
      .k-numeric-wrap {
        input {
          font-size: 1.1rem;
        }
      }
    }

    .k-label {
      top: -0.25rem;
      left: 0;
      font-size: 0.8rem;
      transform: none !important;
    }
  }

  .button-section {
    .k-textbox-container {
      width: 100%;

      .k-dropdown-wrap {
        display: flex;
      }
    }
  }

  .button_container {
    width: 100%;

    button {
      &.k-button {
        width: 100%;
        @include mid-fluid-value(padding-top, 0.45rem, 0.6rem);
        @include mid-fluid-value(padding-bottom, 0.45rem, 0.6rem);
      }
    }
  }
}
