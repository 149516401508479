@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.content_wrapper {
  box-sizing: border-box;
  padding: 2.25rem $content-wrapper-vertical-offset 2.5rem;
  padding-right: 0;
  max-width: calc(100vw - #{$max-side-bar-width});
  margin-left: $max-side-bar-width;
  margin-right: auto;
  z-index: 1;
  @include transition();
  @include laptop-fluid-value(
    padding-top,
    1rem,
    $content-wrapper-vertical-offset
  );

  & > h2 {
    text-align: center;
  }

  &.full_size {
    max-width: 100%;
    min-height: 100vh;
    margin: 0 auto;
  }

  &.wider {
    $left-offset: 4.375rem;

    max-width: calc(100vw - #{$left-offset});
    margin-left: $left-offset;
  }
}
