@import '../styles/mixins.scss';

$icon-size: 2.25rem;
$icon-offset: 1rem;
$switch-offset: ($icon-size * 2.25) + $icon-offset;

.permissions_container {
  svg {
    position: absolute;
    bottom: 0;
    top: $icon-size * 0.65;
    left: $icon-offset;
    width: $icon-size !important;
    height: $icon-size !important;
  }

  .k-form-field > span:first-of-type {
    margin-bottom: 0.25rem;
  }

  .k-switch {
    margin-left: $switch-offset;
  }

  svg {
    @include transition();
  }
}
