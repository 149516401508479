@import '../../styles/mixins.scss';

.reset_password_form_container {
  .k-form {
    .k-form-field {
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }
  }

  legend {
    @include important-laptop-fluid-value(padding-bottom, 0.5rem, 0.75rem);
  }
}
