@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.wrapper {
  padding: 1.25rem 0.5rem;
  padding-bottom: 1.5rem;
  max-width: 300px;
  width: 100%;
  border-radius: 0.45rem;
  text-align: center;
  font-size: 1.15rem;
  text-transform: lowercase;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }

  &,
  .description {
    @include transition();
  }

  &_active,
  &:hover {
    background-color: $accent;

    &,
    .description {
      color: $selected-text;
    }
  }
}

.count {
  font-size: 1.375rem;
  font-weight: bold;
}

.description {
  color: darken($grey-text, 10);
  font-size: 0.75em;
  padding-top: 0.25rem;
  letter-spacing: 0.02em;
}

.sizeWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  flex: 1;

  & + & {
    margin-left: 1.75rem;
  }
}

.wrapper,
.sizeWrapper {
  max-width: 300px;

  @media screen and (max-width: 1444px) {
    max-width: unset;
  }
}

.dropdownWrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
}
