@import '../../styles/theme-variables.scss';

.star_header {
  text-align: center;

  &:hover {
    cursor: pointer !important;
  }

  .icon {
    display: inline-block;
    margin: 0 auto;
    color: $series-b;
  }
}
