$base-theme: Default;
$skin-name: hh-theme;
$swatch-name: Default Green;
$border-radius: 2px;
$accent: #006656;
$info: #00afcb;
$success: #76d750;
$warning: #ff5800;
$error: #d04747;
$text-color: #565a5c;
$bg-color: #ffffff;
$base-text: #565a5c;
$base-bg: #ffffff;
$hovered-text: #565a5c;
$hovered-bg: #cdcfcb;
$selected-text: #ffffff;
$selected-bg: #76d750;
$series-a: #ff5800;
$series-b: #ffd246;
$series-c: #76d750;
$series-d: #00afcb;
$series-e: #22505f;
$series-f: #006656;
