@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

$link-line-height: 0.2rem;
$link-line-starting-width: 25%;

.form_container {
  width: 65%;
  @include transition();

  @media screen and (max-width: 1550px) {
    width: 85%;
  }
}

.succeded_reset {
  p {
    text-align: center;
    font-size: 1.25rem;
  }

  a {
    position: relative;
    color: $accent;
    font-weight: 600;

    &::before {
      content: '';
      position: absolute;
      width: $link-line-starting-width;
      height: $link-line-height;
      bottom: -$link-line-height;
      background-color: $accent;
      border-radius: 1rem;

      @include transition(width);
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }
  }
}
