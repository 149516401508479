@import '../../styles/theme-variables.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$max-side-menu-height: calc(100vh - #{$header-height});
$horizontal-padding: 0.25rem;
$scrollbar-width: 0.5rem;

.side_menu_container {
  position: fixed;
  left: 0;
  // Ten rem na końcu to jest suma paddingu i marginu z
  top: calc(74px + #{$content-wrapper-vertical-offset} - 0.5rem);
  width: 100%;
  padding: 0 $horizontal-padding;
  padding-right: $horizontal-padding + $scrollbar-width;
  padding-bottom: 1rem;
  max-width: $max-side-bar-width;
  background-color: transparent;
  min-height: $max-side-menu-height;
  max-height: $max-side-menu-height;
  height: fit-content;
  z-index: 1;
  text-align: center;
  overscroll-behavior: contain;
  transition: all 350ms ease, padding 0ms ease;

  @include scrollableComponent();

  &:hover {
    padding-right: $horizontal-padding;
  }

  &,
  div,
  span,
  section {
    box-sizing: border-box;
  }

  & + .content_wrapper {
    flex: 1;
  }

  &.toggled {
    max-width: 60px;
    top: 70px;
  }

  ul {
    flex-direction: column;
  }
}
