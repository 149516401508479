@import '../../../styles/theme-variables.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

$horizontal-offset: 2.5rem;
$vertical-offset: 2rem;
$border-radius-size: 0.75rem;
$scrollbar-width: 0.65rem;

.preview_pane {
  position: fixed !important;
  right: 0;
  top: 148px;
  max-height: calc(100vh - 148px);
  max-width: calc(
    (100% - #{$preview-layout-column-spacing * 1.5} - 70px) / 2.618
  );
  padding: $vertical-offset $horizontal-offset;
  background-color: $accent;
  border-radius: $border-radius-size 0 0 $border-radius-size;
  color: $selected-text;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;

  @include laptop-fluid-value(padding-top, 1.5rem, $vertical-offset);
  @include laptop-fluid-value(padding-bottom, 1.5rem, $vertical-offset);
  @include laptop-fluid-value(padding-left, 1.875rem, $horizontal-offset);
  @include laptop-fluid-value(
    padding-right,
    1.875rem - $scrollbar-width,
    $horizontal-offset - $scrollbar-width
  );

  @include transition();

  // Style scrollbar-u
  &::-webkit-scrollbar {
    width: $scrollbar-width;
    background-color: $hovered-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darken($accent, 7);
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px -2px rgba($text-color, 0.5);
    -webkit-box-shadow: inset 0 0 10px -2px rgba($text-color, 0.5);
    background-color: $accent;
  }

  &.narrowed {
    max-width: calc(
      ((100% - 210px - #{$preview-layout-column-spacing}) / 2.618)
    );
  }
}
