@import '../../styles/mixins.scss';

$containerTransitionTime: 550ms;

$itemTransitionTime: 150ms;
$itemTransitionDelay: 50ms;

$max-element-quantity: 10;

.reveal_container {
  position: relative;
  width: 100%;
  transition: all 350ms ease;
  height: fit-content;

  &:not(.hidden) {
    @for $i from 1 through $max-element-quantity {
      &.children_#{$i} {
        transition: all $containerTransitionTime ease;
        animation: revealItem
          $containerTransitionTime
          ease-out
          forwards !important;
      }
    }
  }

  & > * {
    opacity: 1;
  }

  &.hidden {
    height: 0 !important;

    @for $i from 1 through $max-element-quantity {
      &.children_#{$i} {
        transition: all
          $containerTransitionTime
          ($itemTransitionTime + $itemTransitionDelay) *
          $i
          ease;

        @for $j from 1 through $max-element-quantity {
          & > *:nth-last-child(#{$j}) {
            transition: opacity
              $containerTransitionTime
              ($itemTransitionDelay + ($itemTransitionTime * $j))
              ease;
          }
        }
      }
    }

    & > * {
      opacity: 0;
    }
  }

  &:not(.hidden) {
    & + label {
      @include laptop-fluid-value(margin-top, 0.25rem, 0.75rem);
    }
  }
}

@keyframes revealItem {
  0%,
  #{(($containerTransitionTime - $itemTransitionTime) / $containerTransitionTime)
    *
    100%} {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
