@import '../styles/theme-variables.scss';
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

div.user_widget_row {
  width: fit-content;
  align-self: center;

  & > * {
    &:not(:last-of-type) {
      margin-right: 0.75rem;
    }
  }
}

.user_widget_container {
  position: relative;
  margin-right: 2.5rem;
  @include flex();
}

.username_container {
  padding: 0.75rem 0.8rem;

  span:not(:first-of-type) {
    margin-left: 0.5rem;
  }
}

.menu_container {
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: 3.4rem;
}

.menu {
  position: relative;
  width: 100%;
  background-color: $base-bg;
  border-radius: $border-radius;
  overflow: hidden;
  z-index: 10;
  @include flex();
  @include box-shadow();

  & > div {
    .option-name,
    a {
      flex: 1;
      color: $text-color;
      font-weight: 500;
      margin-left: 0;
    }

    a {
      display: flex;
    }
  }
}

.menu_icon {
  padding: 0 $menu-item-offset;
  color: $text-color;
}

.avatar {
  width: 30px;
  margin-right: 0.7rem;
}

span.avatar {
  margin-right: 0.2rem;
}
