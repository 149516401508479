@import '../../styles/mixins.scss';
@import '../../styles/theme-variables.scss';

div.content {
  margin: 0 auto 0.75em 0.75em;
  flex: 6;
  box-sizing: border-box;
  justify-content: start;

  .textarea {
    $textarea-padding: 0.75em;
    $textarea-default-height: 1.25em;

    padding: $textarea-padding;
    height: $textarea-default-height + $textarea-padding * 2;
    min-height: $textarea-default-height + $textarea-padding * 2;
    width: calc(100% - 0.75em);
    border-radius: 0.75rem;
    color: $selected-text !important;
    background-color: $accent;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.14), inset -2px -1px 8px #1f7a6d,
      inset 4px 4px 8px rgba(0, 0, 0, 0.25),
      inset 10px 10px 60px rgba(0, 0, 0, 0.25) !important;
    font-weight: 400;
    @include mid-fluid-value(font-size, 0.8rem, 1rem);
    border: none;
    resize: none;
    box-sizing: border-box;
    @extend %textSelectionOnAccentBackground;
  }

  .status_label {
    margin-top: 0;
    color: rgba(darken($selected-text, 20), 0.5);
    @include important-mid-fluid-value(font-size, 0.8625rem, 1.15rem);
    @include transition();
  }

  .date {
    margin-top: 0.5em;
    font-weight: 500;
    color: rgba($selected-text, 0.7);
    @include important-mid-fluid-value(font-size, 0.75rem, 0.85rem);
  }

  .children_container {
    @include mid-fluid-value(margin-top, 0.5em, 0.75em);
    @include mid-fluid-value(margin-bottom, 0.5em, 0.75em);
  }

  .date,
  .children_container {
    @include mid-fluid-value(margin-left, 0.15em, 0.85em);
  }
}
