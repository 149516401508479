.button {
  &_label {
    margin-bottom: 0.25em;
  }

  &_container {
    align-self: flex-end;

    & + & {
      margin-left: 1.5rem;
    }
  }
}
