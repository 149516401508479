@import '../../styles/theme-variables.scss';

.avatar_placeholder {
  display: block;
  position: relative;
  width: 6rem;
  height: 6rem;
  background-color: $accent;
  border-radius: 50%;

  &::before {
    content: attr(data-label);
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    font-size: 4rem;
    color: $selected-text;
    text-align: center;
    text-transform: uppercase;
  }

  &.small_placeholder {
    width: 30px;
    height: 30px;

    &::before {
      font-size: 0.85rem;
    }
  }
}
