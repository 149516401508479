@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.summary {
  width: 100%;
  // NOTE: Rozmiar przycisków na górze ekranu + jeden margin bottom ich kontenerka
  max-height: calc(95vh - 3.75em);
  display: flex;
  flex-direction: column;
  background-color: $accent;
  border-radius: 0.75rem;
  color: white;
  box-sizing: border-box;

  @include mid-fluid-value(padding-top, 1.5rem, 2rem);
  @include mid-fluid-value(padding-right, 1.25rem, 1.5rem);
  @include mid-fluid-value(padding-bottom, 1.5rem, 2rem);
  @include mid-fluid-value(padding-left, 1.25rem, 1.5rem);
  @include mid-fluid-value(margin-bottom, 3rem, 3.5rem);

  & > * {
    &:not(:first-child) {
      border-top: 0.0625rem solid rgba($selected-text, 0.7);
      @include mid-fluid-value(margin-top, 1rem, 1.5rem);
      @include mid-fluid-value(padding-top, 1rem, 1.5rem);
    }
  }

  svg {
    color: $selected-text;
  }

  div {
    font-size: 1rem;
    font-weight: 600;

    @include quickTransition(font-size);
    @extend %normalLaptopFontSize;
  }

  span {
    display: block;
    font-weight: 500;
    margin-bottom: 0.25rem;
    color: rgba($selected-text, 0.9);

    @include quickTransition();
    @include mid-fluid-value(font-size, 0.65rem, 0.8rem);
  }

  .accent {
    font-weight: 600;
  }

  .timeline_container {
    width: calc(100% - #{$scrollbar-width});
    overflow: auto;
    @include scrollableComponent(
      $accent,
      darken($accent, 7),
      lighten($accent, 7)
    );

    &:hover {
      width: 100%;
    }
  }

  .content_row {
    padding-bottom: 0.75rem;
    justify-content: space-between;

    &:not(:first-of-type) {
      padding-top: 0.75rem;

      @include mid-fluid-value(padding-bottom, 0.35rem, 0.25rem);
    }

    & > div:not(.bigger_flex) {
      flex: 1;

      &:nth-of-type(2) {
        justify-content: center;
      }

      &:nth-of-type(3) {
        justify-content: flex-end;
      }
    }
  }

  .first_icon {
    @include mid-fluid-value(padding-left, 1.25rem, 0rem);
    @include mid-fluid-value(padding-right, 1.8rem, 0.75rem);
  }

  .bigger_flex {
    flex: 1.2;
  }

  .first_row {
    & > div {
      flex: 1;

      @media screen and (max-width: $medium-laptop-screen) {
        &:first-of-type,
        &:last-of-type {
          flex: 1.2;
        }
      }
    }
  }
}
