@import '../../styles/theme-variables.scss';
@import '../../styles/animations.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.bell {
  position: relative;
  font-size: $small-avatar-size;
  height: fit-content;
  align-self: center;
  @include flex(row, center, center);

  &_icon {
    color: $grey-notification-bell;
    width: 0.9em;
    height: 0.9em;
    @include transition();

    &_animating {
      color: $error;
      animation: ring 4000ms ease-in-out infinite;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
