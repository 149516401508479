@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

.link {
  font-weight: 600;
  color: $text-color;
  letter-spacing: -0.02em;
  max-width: 100%;
  @include transition();

  &:hover,
  &.active {
    &,
    svg,
    span {
      color: $accent;
    }
  }

  &_hidden {
    max-width: 2rem;
  }
}

.link_icon {
  width: 1.4rem !important;
  height: 1.4rem !important;
  padding-right: 0.95rem;
}

.arrow_icon {
  width: 1.2rem !important;
  height: 1.2rem !important;
  align-self: center;
}

.link_icon,
.arrow_icon {
  padding-left: 0.75rem;
  color: $text-color;

  @include transition(color);
}

.sublink_container {
  padding-left: 3.15rem;

  &_closed {
    display: none !important;
  }
}
