@import '../styles/theme-variables.scss';
@import '../styles/mixins.scss';

$cardColors: (
  'blue': $info,
  'red': $error,
);

// TODO Vorbert: Zastanowić się nad wydzieleniem tych zmiennych do odrębnego pliku DashboardCardBoxVariables.scss
$boxCardVerticalOffset: 1rem;
$boxCardHorizontalOffset: 1.75rem;

.box {
  --quantity-text-color: #{$accent};
  --description-text-color: #{darken($grey-text, 10)};
  --background-color: #{$selected-text};
  --hover-background-color: #{$accent};
  --hover-text-color: #{$selected-text};

  padding: 1rem 0.25rem;
  text-align: center;
  border-radius: 0.45rem;
  box-sizing: border-box;
  word-wrap: break-word;
  user-select: none;
  background-color: var(--background-color);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  &,
  & > div {
    @include transition();
  }

  &_wrapper {
    padding-right: $boxCardHorizontalOffset;
    padding-top: $boxCardVerticalOffset;
    box-sizing: border-box;

    & {
      flex-basis: 33%;
      max-width: 290px;
      min-width: 240px;
    }
  }

  &_quantity {
    margin-bottom: 0.25rem;
    font-weight: 700;
    font-size: 1.375rem;
    color: var(--quantity-text-color);
  }

  &_label {
    font-weight: 500;
    font-size: 0.8125rem;
    color: var(--description-text-color);
    letter-spacing: 0.02em;
    word-break: break-word;
    text-transform: lowercase;
  }

  &:hover {
    background-color: var(--hover-background-color);
    cursor: pointer;

    .box_quantity,
    .box_label {
      color: var(--hover-text-color);
    }
  }

  @each $colorName, $pickedColor in $cardColors {
    &.#{$colorName} {
      $pickedOpaqueColor: mix($pickedColor, $selected-text, 30%);

      --quantity-text-color: #{$pickedColor};
      --description-text-color: #{$pickedColor};
      --background-color: #{$pickedOpaqueColor};
      --hover-background-color: #{$pickedColor};
      --hover-text-color: #{$selected-text};
    }
  }
}
