@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

$avatar-shadow: 0.35em 0 0.75em -0.15em rgba(23, 23, 23, 0.45);
$avatar-reversed-shadow: -0.35em 0 0.75em -0.15em rgba(23, 23, 23, 0.45);
$avatar-hover-shadow: inset 0 0 0.5em 1em $selected-text;
$reseted-box-shadow: inset 0 0 0 0 transparent;
$circle-avatar-color: $info;

div.container {
  --max-name-width: 20em;
  --max-phone-number-width: 20em;
  @include important-mid-fluid-value(font-size, 0.75rem, 1rem);

  &,
  & > * {
    box-sizing: border-box;
  }

  &:hover {
    cursor: pointer;

    .avatar {
      box-shadow: $avatar-shadow, $avatar-hover-shadow;
      transform: translateY(-0.05em) scale(1.15);

      &::before {
        opacity: 0;
      }

      .icon {
        opacity: 1;
        transition: opacity 350ms 150ms ease-in-out;
      }
    }

    .information {
      color: $circle-avatar-color;
      background-color: $selected-text;
      box-shadow: 0.5em 0.25em 1.75em -0.5em rgba(23, 23, 23, 0.25);
      transition: all 200ms ease-out;

      &_name {
        max-width: 0;
      }

      &_phone_number {
        max-width: var(--max-phone-number-width);
      }
    }
  }

  .avatar {
    position: relative;
    width: 2.25em;
    min-width: 2.25em;
    height: 2.25em;
    background-color: $circle-avatar-color;
    border-radius: 50%;
    z-index: 1;
    box-shadow: $avatar-shadow, $reseted-box-shadow;
    transition: all 200ms ease-out;

    &::before {
      content: attr(data-letter);
      font-size: 1em;
      font-weight: 500;
      color: $selected-text;
      opacity: 1;
      @include centerAbsolutely();
      @include quickTransition();
    }

    .icon {
      font-size: 1em;
      opacity: 0;
      color: $circle-avatar-color;
      transition: opacity 150ms ease-in-out;
      @include centerAbsolutely();
    }

    &_with_image {
      background-repeat: no-repeat;
      background-position: left center;
      background-size: contain;

      &::before {
        content: '';
        color: transparent;
        background-color: transparent;
      }
    }
  }

  .information {
    position: relative;
    margin-left: -0.75em;
    padding: 0.5em 1.25em;
    padding-right: 1em;
    height: fit-content;
    box-shadow: 0 0 0 0 transparent;
    background-color: mix($accent, $selected-text, 70);
    border-radius: 1.75rem;
    color: $accent;
    font-weight: 600;
    user-select: none;
    transition: all 200ms ease-out, color 50ms ease;
    @include important-mid-fluid-value(font-size, 0.75rem, 0.85rem);

    &_reverse {
      margin-left: 0;
      margin-right: -0.75em;
      padding-right: 1.25em;
      padding-left: 1em;
    }

    &_name,
    &_phone_number {
      font-size: inherit;
      display: inline-block;
      font-size: inherit !important;
      line-height: 1em;
      white-space: nowrap;
      overflow: hidden;
      @include transition();
    }

    &_name {
      max-width: var(--max-name-width);
    }

    &_phone_number {
      max-width: 0;
    }
  }

  &_reverse {
    flex-direction: row-reverse;

    .avatar {
      box-shadow: $avatar-reversed-shadow, $reseted-box-shadow;
    }

    &:hover {
      .avatar {
        box-shadow: $avatar-reversed-shadow, $avatar-hover-shadow;
      }
    }
  }

  &_disabled {
    &:hover {
      cursor: default;
    }
  }
}

div.container {
  width: fit-content;
}
