@import '../../../styles/theme-variables.scss';
@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.dropdown {
  position: absolute;
  top: -0.5em;
  left: 100%;
  // NOTE: To jest zapewnienie, że pomimo tego, iż dropdown jest oddalony od linku, to user będzie mógł najechać na dropdown-a
  font-size: 0.85rem;
  padding-left: 0.9em;
  opacity: 1;
  @include quickTransition();

  &_hidden {
    opacity: 0;
    pointer-events: none;
  }

  &_body {
    box-sizing: border-box;
    box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.06);
    background-color: transparent;
    border-radius: 0.25rem;
  }
}
