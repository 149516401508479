@import '../../../styles/theme-variables.scss';
@import '../../../styles/mixins.scss';

.section_title {
  position: relative;
  padding-bottom: 0.45rem;
  @include laptop-fluid-value(margin-bottom, 0.9rem, 1.2rem);

  &::before {
    content: '';
    position: absolute;
    bottom: -0.125rem;
    width: 100%;
    height: 0.125rem;
    background-color: rgba($selected-text, 0.25);
  }

  .icon {
    color: $selected-text;
    align-self: center;
    @include laptop-fluid-value(width, 2rem, 2.5rem);
    @include laptop-fluid-value(height, 2rem, 2.5rem);
  }

  .title {
    margin-left: 0.75rem;
    font-weight: 500;
    @include laptop-fluid-value(font-size, 1.25rem, 1.75rem);
  }
}
