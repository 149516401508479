@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

$colorClasses: (
  'red': $error,
  'orange': $warning,
  'blue': $info,
  'darkBlue': $series-e,
  'green': $success,
  'darkGreen': $accent,
  'customGreen': $custom-green,
  'dark': $text-color,
  'yellow': $series-b,
  'grey': $text-color,
);

$animation-values: 6000ms 1500ms ease-out infinite;
$marginalElementsOffset: 0.05em;
$verticalItemOffset: 0.5rem;
$circle-size: 1em;

a.link {
  --sublink-color: #{$accent};
  display: flex !important;
  // Importanty jest dlatego, ze style z themingu są bardziej "dokreślone"
  margin: 0.2rem 0 !important;
  padding-top: 0.15em;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  font-size: 0.95rem;
  font-weight: 600;
  transition: all 350ms ease, padding 450ms ease-in-out;
  overflow: visible !important;
  @include important-laptop-fluid-value(margin-top, 0rem, 0.2rem);
  @include important-laptop-fluid-value(margin-bottom, 0rem, 0.2rem);
  @include flex(row, space-between, center);

  &::before {
    content: '';
    position: absolute;
    right: calc(100% + 1rem);
    width: $circle-size * 0.6;
    height: $circle-size * 0.6;
    border-radius: 50%;
    background-color: var(--sublink-color);
    transform-origin: 50% 50%;
    opacity: 0;
    @include transition();
    @include centerAbsolutely(vertical);
  }

  &::after {
    display: none;
  }

  &:hover,
  &_active {
    color: var(--sublink-color) !important;

    &:not(:first-of-type) {
      padding-top: $verticalItemOffset + 0.15rem !important;
    }

    &:not(:last-of-type) {
      padding-bottom: $verticalItemOffset !important;
    }

    &:only-child {
      padding-top: $verticalItemOffset !important;
      padding-bottom: $verticalItemOffset !important;
    }
  }

  &:hover {
    color: var(--sublink-color) !important;

    &::before {
      opacity: 1;
      background-color: var(--sublink-color);
    }
  }

  &:not(:only-child) {
    &:first-of-type {
      &::before {
        top: calc(50% - #{$verticalItemOffset * 0.35});
      }
    }

    &:last-of-type {
      &::before {
        top: initial;
        bottom: calc(50% - #{$verticalItemOffset * 0.35});
        transform: translateY(50%);
      }
    }
  }

  &_active {
    &::before {
      right: calc(100% + 0.85rem);
      width: $circle-size;
      height: $circle-size;
      opacity: 1;
      animation: pulse-dark-green $animation-values;
    }

    @each $name, $value in $colorClasses {
      &.#{$name} {
        &::before {
          animation: pulse-#{$name} $animation-values;
        }
      }
    }
  }

  @each $name, $value in $colorClasses {
    &.#{$name} {
      --sublink-color: #{$value};
    }
  }
}

@each $name, $value in $colorClasses {
  @keyframes pulse-#{$name} {
    0% {
      box-shadow: 0 0 0 0 rgba($value, 0.6);
    }

    28% {
      box-shadow: 0 0 0 0.65em rgba($value, 0);
    }

    40% {
      box-shadow: 0 0 0 0 rgba($value, 0);
    }
  }
}
