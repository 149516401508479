@import '../styles/mixins.scss';

.accordion_button {
  margin-bottom: 0 !important;
  @include important-laptop-fluid-value(margin-top, 1.75rem, 2rem);

  &:nth-child(2) {
    margin-top: 0.75rem !important;
  }
}
