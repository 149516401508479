@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$table-breakpoint: 700px;

.bids_table {
  min-width: 300px;
  border-collapse: collapse;
  text-align: left;
  overflow: auto;

  &_container,
  &_revealer {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &_revealer {
    transition: all 550ms ease, padding-right 0ms ease !important;
    @include scrollableComponent(
      $accent,
      darken($accent, 7),
      lighten($accent, 7),
      true
    );
  }

  &_quantity {
    display: inline-block;
    font-weight: 600;
    margin-left: 0.15em;
    margin-right: 0.75em;
    @include mid-fluid-value(font-size, 0.85rem, 1rem);

    &_description {
      letter-spacing: 0.02em;
      @include mid-fluid-value(font-size, 0.75rem, 0.85rem);
    }
  }

  thead {
    tr {
      th {
        $index-header-width: 4%;
        $badge-header-width: 9rem;

        font-size: 0;

        &.header {
          &_index {
            width: $index-header-width;
          }

          &_suppliers {
            width: calc(100% - #{$index-header-width} - #{$badge-header-width});
          }

          &_badge {
            width: $badge-header-width;
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &,
        span:not([class]) {
          @include mid-fluid-value(font-size, 0.85rem, 1rem);
          font-weight: 500;
        }

        &.cell {
          &_index {
            text-align: center;
          }

          &_company_name {
            line-height: 130%;

            span {
              display: inline-block;
              margin-top: -0.15em;
            }
          }
        }
      }
    }
  }

  td {
    display: block;

    &:before {
      content: attr(data-table-header) ': ';
      display: inline-block;
      width: 8em;
      font-weight: 700;

      @media (min-width: $table-breakpoint) {
        display: none;
      }
    }

    span {
      font-weight: 600;

      &.index_cell {
        margin-left: 0;
      }
    }
  }

  td {
    text-align: left;
    vertical-align: top;

    @media (min-width: $table-breakpoint) {
      display: table-cell;
      padding: 0.75em 0.5em;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    @media screen and (max-width: $big-laptop-screen) {
      line-height: 125%;
    }
  }

  .badge {
    margin-left: auto;
    margin-right: 0;
    font-size: 1em;
    font-weight: 600;

    @media screen and (max-width: $table-breakpoint) {
      display: none;
    }
  }
}
