@import '../../styles/mixins.scss';

.additional_top_offset {
  margin-top: 0.35rem;
}

form.form {
  max-width: initial;
}

.form {
  &_row {
    &,
    & > div {
      box-sizing: border-box;
    }

    & > div {
      width: 100%;

      &:first-of-type {
        @include mid-fluid-value(padding-right, 0rem, 1.25rem);
      }

      &:last-of-type {
        @include mid-fluid-value(padding-left, 4.75rem, 5.75rem);
      }
    }
  }
}
