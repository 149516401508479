@import '../../styles/mixins.scss';

.button_row {
  position: relative;

  @include flex(row);

  /* Przy wdrażaniu mobilek
      flex-direction: column;
    */

  & > *:not(button) {
    width: 100%;
  }

  & > button {
    position: absolute;
    top: 10%;
    left: calc(100% + 2.5rem);

    /* Przy wdrażaniu mobilek
        position: relative;
        top -> wywalić
        left -> wywalić
      */
  }
}
