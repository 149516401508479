@import '../../../styles/theme-variables.scss';
@import '../../../styles/mixins.scss';

.list_container {
  &:not(:nth-of-type(2)) {
    margin-top: 0.9rem;
    @include laptop-fluid-value(font-size, 0.6rem, 0.9rem);
  }

  .label {
    color: rgba($selected-text, 0.7);
    @include laptop-fluid-value(font-size, 0.85rem, 1rem);
  }

  .value_list {
    margin-left: 0.6rem;

    .value {
      @include laptop-fluid-value(font-size, 0.85rem, 1rem);

      &:not(:first-of-type) {
        margin-top: 0.5rem;
      }
    }
  }
}
