@import '../../../styles/variables.scss';
@import '../../../styles/theme-variables.scss';
@import '../../../styles/mixins.scss';

$scrollbar-width: 0.5rem;

.content {
  padding-right: 0.5rem;
  border: 2px solid rgba($text-color, 0.15);
  flex: 1;
  overflow: scroll;

  @include scrollStyling();
  @include mid-fluid-value(padding-top, 1.5rem, 2rem);
  @include mid-fluid-value(padding-bottom, 1.5rem, 2rem);
  @include mid-fluid-value(padding-left, 1rem, 1.75rem);

  &:hover {
    padding-right: 0.5rem - $scrollbar-width;
  }

  @media screen and (max-width: $medium-laptop-screen) {
    padding-right: 2.25rem;

    &:hover {
      padding-right: 2.25rem - $scrollbar-width;
    }
  }

  div span:not([class]) {
    display: block;
    color: $text-color;
    @include mid-fluid-value(margin-bottom, 0.15rem, 0.45rem);
  }

  // Dodany 'div' przed 'svg', żeby zasada była bardziej 'doprecyzowana' niż zasada z AuctionPage.scss
  div svg {
    color: $accent;
    align-self: flex-start;
  }
}

.header_label {
  color: $accent;
  font-weight: 500;
  @include quickTransition();
  @include mid-fluid-value(font-size, 1.25rem, 1.5rem);
}

.description_label {
  @include mid-fluid-value(margin-top, 0.15rem, 0.5rem);
  @include mid-fluid-value(font-size, 0.75rem, 0.9rem);
}

.badge {
  background-color: $series-d;
  padding: 0.2rem 1rem;
  border-radius: 20px;
}

.bigger_data {
  & > * {
    &:not(span) {
      font-size: 1.75rem;
    }
  }
}

.content_column {
  & > div {
    & > div {
      box-sizing: border-box;

      &:first-of-type {
        @include mid-fluid-value(padding-right, 0rem, 1.25rem);
      }

      &:nth-of-type(2) {
        @include mid-fluid-value(padding-left, 4.75rem, 4.75rem);
      }
    }
  }
}
