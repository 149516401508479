@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';

$counter-name: information-list-counter;

.list {
  &_container {
    color: $accent;
    padding-left: 0;
    list-style: none;
    @include mid-fluid-value(font-size, 0.85rem, 1rem);
    counter-reset: $counter-name;

    li {
      position: relative;
      counter-increment: $counter-name;
      padding-left: 1.5em;

      &::before {
        content: counter($counter-name) '.';
        position: absolute;
        left: 0;
        top: 0;
        margin-right: 0.25em;
        margin-top: 0.175em;
        font-weight: 600;
        line-height: 1;
      }
    }
  }
}
