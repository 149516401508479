@import '../styles/theme-variables.scss';
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

$default-icon-size: 4rem;
$card-offset: 1.625rem;
$card-item-offset: 1.75rem;
$card-title-font-size: 1.875rem;

.card {
  &_container {
    max-width: unquote('min(calc(100% - 5.125rem), 1400px)');
    margin-left: 2.75rem;
    height: fit-content;
    padding: 3.25rem;
    border-radius: 0.35rem;
    box-sizing: border-box;
    @include box-shadow(0, 7px, 29px, 0px);

    & + & {
      margin-top: 2.5rem;
    }

    & > div {
      &:not(:last-of-type) {
        margin-bottom: $card-item-offset;
      }
    }

    svg {
      &.icon {
        height: $default-icon-size;
        width: $default-icon-size;
        margin-right: 1.25rem;
      }
    }

    .title {
      font-size: $card-title-font-size;
      font-weight: 700;
      color: darken($text-color, 10);
    }

    .event {
      &_title {
        margin-bottom: 0.35rem;
      }

      &_quantity {
        position: relative;
        display: inline-block;
        font-weight: 700;
        font-size: 1.375rem;
        text-transform: lowercase;
        color: $accent;

        &:hover {
          .event_link_arrow {
            transform: translateX(0.75rem);
          }
        }
      }

      &_link_arrow {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.75rem;
        transform: translateX(0);
        @include transition();
      }
    }
  }
}
