@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.bids {
  display: flex;
  height: 100%;
  background-color: $accent;
  color: white;
  flex-direction: column;
  border-radius: 0.75rem;

  // TODO vorbert -> zrobić mixinsy przyjmujące więcej niz 1 wartość do kalkulacji xD
  @include mid-fluid-value(padding-top, 1.5rem, 2rem);
  @include mid-fluid-value(padding-right, 1.75rem, 2.5rem);
  @include mid-fluid-value(padding-bottom, 1.5rem, 2rem);
  @include mid-fluid-value(padding-left, 1.75rem, 2.5rem);

  @include small-laptop-fluid-value(padding-top, 1.25rem, 1.5rem);
  @include small-laptop-fluid-value(padding-bottom, 1.25rem, 2rem);
  @include small-laptop-fluid-value(padding-left, 1.25rem, 1.75rem);
  @include small-laptop-fluid-value(padding-right, 1.25rem, 1.75rem);

  & > * {
    &:not(:last-child) {
      @include mid-fluid-value(margin-bottom, 1.25rem, 2.5rem);

      @include small-laptop-fluid-value(margin-bottom, 0.75rem, 1.25rem);
    }
  }
}
