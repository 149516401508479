@import '../../styles/theme-variables.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.current_price_section {
  svg {
    color: $selected-text;
  }

  // TODO Vorbert -> Ujednolicić dla wszystkich komponentów widoku aukcji
  div {
    font-size: 1rem;
    font-weight: 600;

    transition: all $quick-transition-time ease, color 900ms ease-in-out;
    @include mid-fluid-value(font-size, 0.8rem, 1rem);
  }

  span {
    display: block;
    font-weight: 500;
    margin-bottom: 0.25rem;
    color: rgba($selected-text, 0.9);

    @include quickTransition();
    @include important-mid-fluid-value(font-size, 0.63rem, 0.8rem);
  }

  .accent {
    font-weight: 600;
  }

  .content_row {
    padding-bottom: 0.75rem;
    justify-content: space-between;

    &:not(:first-of-type) {
      padding-top: 0.75rem;

      @include mid-fluid-value(padding-bottom, 0.35rem, 0.25rem);
    }

    & > div:not(.bigger_flex) {
      flex: 1;

      &:nth-of-type(2) {
        justify-content: center;
      }

      &:nth-of-type(3) {
        justify-content: flex-end;
      }
    }
  }

  .border_row {
    border-bottom: 0.0625rem solid rgba($selected-text, 0.7);
  }

  .first_icon {
    @include mid-fluid-value(padding-left, 0.75rem, 1.25rem);
    @include mid-fluid-value(padding-right, 1.2rem, 1.8rem);
  }
}

.bigger_flex {
  flex: 1.4;

  &:last-of-type {
    flex: 1.2;
  }

  @media screen and (max-width: $medium-laptop-screen) {
    &:last-of-type {
      flex: 1.2;
    }

    &:first-of-type {
      flex: 1.6;
    }
  }
}
