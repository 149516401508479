@import '../../../styles/theme-variables.scss';
@import '../../../styles/mixins.scss';

$padding-vertical: 2.5rem;
$padding-horizontal: 3rem;

.card_wrapper {
  margin: 0 auto;
  width: 100%;
  background-color: $bg-color;
  border: 0.125rem solid rgba($text-color, 0.2);
  border-radius: 4 * $border-radius;
  box-sizing: border-box;
  // TODO Vorbert -> odbugować w chromie czarną przestrzeń, która tworzy sie tam, gdzie border radius zostaje zmieniany na 0
  @include laptop-fluid-value(padding-top, 1.875rem, $padding-vertical);
  @include laptop-fluid-value(padding-bottom, 1.875rem, $padding-vertical);
  @include laptop-fluid-value(padding-right, 2.25rem, $padding-horizontal);
  @include laptop-fluid-value(padding-left, 2.25rem, $padding-horizontal);
  @include box-shadow(0, 4px, 32px, -10px);
  @include quickTransition();
}
