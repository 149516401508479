.transport_order_page {
  .k-form-field {
    & > span {
      &:first-child {
        padding-top: 0 !important;
        margin-bottom: 0;
      }
    }
  }
}
