@import '../../../styles/theme-variables.scss';
@import '../../../styles/mixins.scss';

.menu {
  &_container {
    a {
      position: relative;
      display: inline-block;
      margin: 0.5rem 0;
      padding: 0.3rem 1.075rem 0.3rem 0.375rem;
      width: 100%;
      text-align: left;
      font-size: 0.95rem;
      color: rgba($text-color, 0.6);
      opacity: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include transition(all);

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0.225rem;
        width: 0.2rem;
        height: 0;
        border-radius: $border-radius;
        background-color: $accent;
        @include transition(height, ease-out);
      }

      &:active,
      &:hover {
        &::after {
          height: 100%;
        }
      }

      &.active,
      &:hover {
        color: $accent;
      }

      span:not([class]) {
        vertical-align: middle;
        @include transition(opacity);
      }
    }

    & > div {
      max-width: 100%;

      &:first-of-type {
        margin-top: 0;
      }

      .k-link {
        width: 100%;
        padding: 0.125rem 0.375rem;
        padding-left: 0.175rem;
        @include laptop-fluid-value(padding-top, 0rem, 0.125rem);
        @include laptop-fluid-value(padding-bottom, 0rem, 0.125rem);
      }
    }

    &_toggled {
      & > div {
        .k-item {
          max-width: unset;

          a {
            span:not([class]),
            .link_arrow_icon {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
