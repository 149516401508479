@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$icon-size: 2.5rem;

.icon {
  padding: 0 1.5rem;
  align-self: center;
  @include transition();

  @include mid-fluid-value(padding-left, 0rem, 1.5rem);
  @include mid-fluid-value(padding-right, 1.1rem, 1.5rem);
  @include important-mid-fluid-value(width, $icon-size * 0.75, $icon-size);
  @include mid-fluid-value(height, $icon-size * 0.75, $icon-size);

  &_small {
    padding-left: 0;
    width: $icon-size * 0.75 !important;
    height: $icon-size * 0.75 !important;
  }

  &_wrapper {
    align-self: flex-start;
  }

  &_field {
    @include flex(row, flex-start, center);
  }
}

.children_container {
  align-self: flex-start;
}
