$vertical-offset: 0.75rem;
$horizontal-offset: 0.75rem;

.toast_container {
  position: fixed;
  right: $horizontal-offset;
  max-width: 350px;
  max-height: 100vh;
  z-index: 15;

  &:not(.align_top) {
    bottom: $vertical-offset;

    & > div {
      &:not(:last-of-type) {
        margin-bottom: $vertical-offset;
      }
    }
  }

  &.align_top {
    top: $vertical-offset;

    & > div {
      &:not(:first-of-type) {
        margin-top: $vertical-offset;
      }
    }
  }
}
