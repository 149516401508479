@import '../../styles/variables.scss';
@import '../../styles/theme-variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/animations.scss';

$vertical-offset: 1rem;
$horizontal-offset: 1.5rem;

.item_container {
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  border-radius: 0.75rem;

  @include transition(box-shadow);
  @include laptop-fluid-value(margin-top, 1.5rem, 2rem);
  @include laptop-fluid-value(padding-top, 0.75rem, $vertical-offset);
  @include laptop-fluid-value(padding-bottom, 0.75rem, $vertical-offset);
  @include laptop-fluid-value(padding-left, 1.125rem, $horizontal-offset);
  @include laptop-fluid-value(padding-right, 1.125rem, $horizontal-offset);

  &:focus-within {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 20px rgba(0, 0, 0, 0.07);
  }

  &:nth-of-type(2) {
    margin-top: 0.75rem;
  }
}

.header {
  &:hover {
    cursor: pointer;
  }

  .title {
    font-size: 1.15rem;
    color: $accent;
    font-weight: 500;
    @include laptop-fluid-value(font-size, 0.95rem, 1.15rem);
  }

  .icon_container {
    width: auto;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
      @include transition();
      @include laptop-fluid-value(width, 1.25rem, 1.5rem);
      @include laptop-fluid-value(height, 1.25rem, 1.5rem);

      &_reversed {
        transform: scaleY(-1);
      }

      &_remove {
        color: darken($grey-text, 10);

        &:hover {
          &:not(:active) {
            color: $error;
          }
        }
      }

      &:not(:last-of-type) {
        margin-right: $horizontal-offset * 0.75;
      }
    }
  }

  &_white {
    &,
    .title {
      color: $selected-text;
    }
  }
}

.content {
  padding: 0.75rem 0;

  &_without_bottom_offset {
    padding-bottom: 0;
  }
}
