@import '../../styles/theme-variables.scss';
@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

$circle-offset: 0.15em;

.circle {
  position: absolute;
  top: $circle-offset * -1;
  right: $circle-offset * -1;
  width: 1em;
  height: 1em;
  background-color: $error;
  font-size: 1rem;
  border-radius: 50%;
  z-index: 1;
  user-select: none;

  &_grey {
    background-color: $grey-notification-bell;
  }

  &_number {
    position: absolute;
    font-size: 0.7em;
    color: $selected-text;
    font-weight: 500;
    @include centerAbsolutely();
  }
}
