@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.rowWrapper {
  position: relative;
}

.deleteIconWrapper {
  font-size: 1.25rem;
  position: absolute;
  bottom: 4px;
  left: calc(100% + 1.25rem);
  color: $grey;
  outline: none;
  animation: iconAppearing 350ms ease-out forwards;
  @include quickTransition();

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    color: $error;
  }
}

@keyframes iconAppearing {
  0% {
    opacity: 0;
    transform: translateX(-1.75rem) scale(0.75);
  }

  100% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}
