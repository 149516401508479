.bold {
  font-weight: bold;

  &_medium {
    font-weight: 500;
  }

  &_semi-strong {
    font-weight: 600;
  }
}

// Dodano tutaj flagi !important, bo są to utility klasy, przy uzyciu których nie powinno się zastanawiać nad tym jak "ważne" sa inne zasady.
.reset-margin {
  $properties: left, right, bottom, top;

  @each $property in $properties {
    &-#{$property} {
      margin-#{$property}: 0 !important;
    }
  }

  &-all {
    margin: 0 !important;
  }

  &-first-child {
    @each $property in $properties {
      &-#{$property} {
        & > *:first-child {
          margin-#{$property}: 0 !important;
        }
      }
    }

    &-all {
      & > *:first-child {
        margin: 0 !important;
      }
    }
  }
}

.reset-padding {
  $properties: left, right, bottom, top;

  @each $property in $properties {
    &-#{$property} {
      padding-#{$property}: 0 !important;
    }
  }

  &-all {
    padding: 0 !important;
  }
}

.full-height {
  height: 100% !important;
}

// TODO Vorbert -> zrobić klasę .width i do niej wrzucić fit-content i auto.
.auto-width {
  width: auto !important;
}

.width {
  &-fit-content {
    width: fit-content !important;
  }
}

.height {
  &-fit-content {
    height: fit-content !important;
  }
}

.full {
  &-width {
    width: 100% !important;
  }

  &-height {
    height: 100% !important;
  }
}

.column {
  flex: 1;
  @include flex();

  &--half {
    flex: 0.5;
  }
}

.row {
  flex: 1;
  @include flex(row);

  &--half {
    flex: 0.5;
  }
}

.flex-item {
  flex: 1;
}

.flex-align-vertical-center {
  align-self: center;
}

.flex-align-horizontal-center {
  justify-self: center;
}

.flex-wrapper {
  position: relative;
  width: 100%;
  z-index: 0;

  &-row {
    @include flex(row);
  }

  &-column {
    @include flex();

    &-bigger {
      max-width: calc(
        100% - ((100% - #{$preview-layout-column-spacing}) / 2.4)
      );
    }

    &-bigger-preview {
      max-width: calc(
        100% - ((100% - #{$preview-layout-column-spacing * -2.5}) / 2.618)
      );
    }

    &-smaller {
      max-width: calc((100% - #{$preview-layout-column-spacing}) / 2.4);
    }

    &-smaller-preview {
      max-width: calc((100% - #{$preview-layout-column-spacing}) / 2.618);
    }

    &.rounded {
      border-radius: 0.75rem;
    }

    &.lifted {
      $lifted-box-shadow: 1.5rem 1.25rem 1.75rem 0.75rem rgba(23, 23, 23, 0.45);
      z-index: 1;

      &-left {
        box-shadow: $lifted-box-shadow;
        max-height: 100vh;
      }

      &-right {
        box-shadow: -#{$lifted-box-shadow};
        max-height: 95vh;
      }
    }
  }
}

.vertical-offset {
  $properties: top, bottom;

  @each $property in $properties {
    margin-#{$property}: $default-offset;
  }

  &-big {
    @each $property in $properties {
      margin-#{$property}: $default-offset * $big-scale-size;
    }
  }

  &-small {
    @each $property in $properties {
      margin-#{$property}: $default-offset * $small-scale-size;
    }
  }
}

.horizontal-offset {
  $properties: left, right;

  @each $property in $properties {
    margin-#{$property}: $default-offset;
  }

  &-big {
    @each $property in $properties {
      margin-#{$property}: $default-offset * $big-scale-size;
    }
  }

  &-small {
    @each $property in $properties {
      margin-#{$property}: $default-offset * $small-scale-size;
    }
  }
}

// Tutaj w paddingach/marginach jest !important, bo te zasady służą jako utility klasy, które niejednokrotnie są używane do 'walki z telerikiem' xD
.margin {
  $properties: top, right, bottom, left;

  &-auto {
    margin: 0 auto;
  }

  @each $property in $properties {
    &-#{$property} {
      margin-#{$property}: $default-offset !important;
    }
  }

  &-small {
    @each $property in $properties {
      &-#{$property} {
        margin-#{$property}: $default-offset * $small-scale-size !important;
      }
    }
  }

  &-big {
    @each $property in $properties {
      &-#{$property} {
        margin-#{$property}: $default-offset * $big-scale-size !important;
      }
    }
  }
}

.padding {
  $properties: top, right, bottom, left;

  @each $property in $properties {
    &-#{$property} {
      padding-#{$property}: $default-offset !important;
    }
  }

  &-small {
    @each $property in $properties {
      &-#{$property} {
        padding-#{$property}: $default-offset * $small-scale-size !important;
      }
    }
  }

  &-big {
    @each $property in $properties {
      &-#{$property} {
        padding-#{$property}: $default-offset * $big-scale-size !important;
      }
    }
  }
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.center-absolutely {
  @include centerAbsolutely();
}

.flex {
  $properties: (
    'start': flex-start,
    'center': center,
    'end': flex-end,
  );

  @each $name, $value in $properties {
    &-align-#{$name} {
      align-self: $value;
    }
  }

  &-unset {
    flex: unset !important;
  }
}

.align-text {
  $properties: left, center, right, justify;

  @each $property in $properties {
    &-#{$property} {
      text-align: $property;
    }
  }
}

.align-items {
  $properties: (
    'start': flex-start,
    'center': center,
    'end': flex-end,
  );

  @each $name, $value in $properties {
    &-#{$name} {
      align-items: $value;
    }
  }
}

.align-self {
  $properties: (
    'start': flex-start,
    'center': center,
    'end': flex-end,
  );

  @each $name, $value in $properties {
    &-#{$name} {
      align-self: $value;
    }
  }
}

.align-content {
  $properties: (
    'start': flex-start,
    'center': center,
    'end': flex-end,
  );

  @each $name, $value in $properties {
    &-#{$name} {
      align-content: $value;
    }
  }
}

.justify-items {
  $properties: (
    'start': flex-start,
    'center': center,
    'end': flex-end,
  );

  @each $name, $value in $properties {
    &-#{$name} {
      justify-items: $value;
    }
  }
}

.justify-self {
  $properties: (
    'start': flex-start,
    'center': center,
    'end': flex-end,
  );

  @each $name, $value in $properties {
    &-#{$name} {
      justify-self: $value;
    }
  }
}

.justify-content {
  $properties: (
    'start': flex-start,
    'center': center,
    'end': flex-end,
    'space-between': space-between,
    'space-around': space-around,
    'space-evenly': space-evenly,
  );

  @each $name, $value in $properties {
    &-#{$name} {
      justify-content: $value !important;
    }
  }
}

.center {
  &-horizontally {
    @include centerAbsolutely(horizontal);
  }

  &-vertically {
    @include centerAbsolutely(vertical);
  }

  &-center {
    @include centerAbsolutely();
  }
}

.accent {
  &-text {
    color: $accent;
    font-weight: 500;
  }

  &-background {
    background-color: $accent;
  }
}

.background {
  // Aktualnie nie jest możliwa interpolacja tekstu w nazwę zmiennej, dlatego są tutaj wpisane nazwy zmiennych
  $properties: (
    'warning': $warning,
    'info': $info,
    'accent': $accent,
    'success': $success,
    'error': $error,
  );

  @each $name, $value in $properties {
    &_#{$name} {
      background-color: $value;
    }
  }
}

.text {
  $properties: (
    'warning': $warning,
    'info': $info,
    'accent': $accent,
    'success': $success,
    'error': $error,
    'grey': $hovered-bg,
    'white': $selected-text,
    'green': $custom-green,
  );

  @each $name, $value in $properties {
    &_#{$name} {
      color: $value;
    }
  }

  &_lighten {
    @each $name, $value in $properties {
      &_#{$name} {
        color: mix($value, $selected-text, 70);
      }
    }
  }
}

.display {
  $properties: block, inline, inline-block, inline-flex, flex, none;

  @each $property in $properties {
    &-#{$property} {
      display: $property !important;
    }
  }
}

.overflow {
  $properties: hidden, visible, scroll;

  @each $property in $properties {
    &-#{$property} {
      overflow: $property !important;
    }
  }
}

.shaking-text {
  animation: shake 1100ms linear normal;
}

.shining-text {
  color: $success;
  background: $success
    linear-gradient(-30deg, mix($success, #fff, 45%) 0%, $success 45%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  animation: shiningText 6000ms 1750ms linear infinite;
}

.time {
  &-number,
  &-description {
    color: $info;

    &-grey {
      color: lighten($text-color, 15);
    }
  }

  &-number {
    font-size: 1rem;
    font-weight: 600;

    &-small {
      font-size: 1rem;
    }
  }

  &-description {
    font-size: 0.75rem;
    font-weight: 500;
  }
}
